import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  IconButton,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  AppBar as MuiAppBar,
  Toolbar,
  Badge,
  Divider,
  List,
  Drawer as MuiDrawer,
} from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';

import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { mainListItems, secondaryListItems } from './listItems'; // Assuming you have a separate file for the list items

const drawerWidth = 240;

const theme = createTheme({
  palette: {
    primary: {
      main: '#22C55E', // Lebendiges Grün für Hauptakzente
    },
    secondary: {
      main: '#A7F3D0', // Helles, weiches Grün für Hintergrundelemente
    },
    background: {
      default: '#F0FDF4', // Sehr helles Grün für den Hintergrund
      paper: '#FFFFFF', // Reinweiß für Karten und Container
    },
    text: {
      primary: '#065F46', // Dunkler Grünton für Text
      secondary: '#065F46',
    },
  },
  shape: {
    borderRadius: 16, // Sanfte Rundungen für moderne Optik
  },
  shadows: [
    'none',
    '0px 4px 12px rgba(0, 0, 0, 0.1)', // Sanfter Schatten für Karten
  ],
  typography: {
    fontFamily: 'Arial, sans-serif', // Moderne, saubere Schriftart
    h6: {
      fontWeight: 700,
    },
  },
});

// Aktualisierte Komponentenstile
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: theme.palette.primary.main, // Primärfarbe aus dem Theme
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 345,
  margin: 'auto',
  transition: '0.3s',
  boxShadow: theme.shadows[1], // Angepasster Schatten
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    boxShadow: '0 16px 70px -12.125px rgba(0, 0, 0, 0.3)',
  },
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

function DoctorConfig() {
  const [doctors, setDoctors] = useState([]);
  const [tenants, setTenants] = useState([]);
  const [addDoctorOpen, setAddDoctorOpen] = useState(false);
  const [editDoctorOpen, setEditDoctorOpen] = useState(false);
  const [newDoctor, setNewDoctor] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    specialization: '',
    licenseNumber: '',
    tenantId: '',
    imageUrl: ''
  });
  const [doctorToEdit, setDoctorToEdit] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [open, setOpen] = useState(true);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const fetchDoctors = async () => {
    try {
      const response = await axios.get('api/doctors');
      setDoctors(response.data);
    } catch (error) {
      console.error('Échec du chargement des docteurs', error);
    }
  };

  const fetchTenants = async () => {
    try {
      const response = await axios.get('api/tenants');
      setTenants(response.data);
    } catch (error) {
      console.error('Échec du chargement des locataires', error);
    }
  };

  useEffect(() => {
    fetchDoctors();
    fetchTenants();
  }, []);

  const navigate = useNavigate(); // Verwende useNavigate zum Weiterleiten


  useEffect(() => {
    // Axios-Interceptor zur automatischen Hinzufügung des Tokens zu jeder Anfrage
    const requestInterceptor = axios.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem('token'); // Token aus dem lokalen Speicher abrufen
        if (token) {
          config.headers.Authorization = `Bearer ${token}`; // Token in den Authorization-Header setzen
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    // Axios-Interceptor zur Abfangung von abgelaufenen Token
    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        // Erfolgreiche Antwort - kein Handlungsbedarf
        return response;
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          // Überprüfen, ob der Fehler auf einen abgelaufenen Token hinweist
          if (error.response.data.error === 'Token abgelaufen') {
            // Token aus dem lokalen Speicher entfernen
            localStorage.removeItem('token');
            // Redirect zur Login-Seite mit useNavigate
            navigate('/login');
          }
        }
        return Promise.reject(error);
      }
    );

    // Cleanup function to eject the interceptors when the component unmounts
    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [navigate]); // Abhängigkeit auf navigate setzen
  
  const handleAddDoctor = async () => {
    try {
      const response = await axios.post('api/doctors', newDoctor);
      setDoctors([...doctors, response.data]);
      setAddDoctorOpen(false);
      setNewDoctor({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        specialization: '',
        licenseNumber: '',
        tenantId: '',
        imageUrl: ''
      });
      setSnackbarMessage('Docteur ajouté avec succès.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Échec de l\'ajout du docteur', error);
      setSnackbarMessage('Erreur lors de l\'ajout du docteur.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleEditDoctor = (doctor) => {
    setDoctorToEdit(doctor);
    setNewDoctor({
      firstName: doctor.firstName,
      lastName: doctor.lastName,
      email: doctor.email,
      phoneNumber: doctor.phoneNumber,
      specialization: doctor.specialization,
      licenseNumber: doctor.licenseNumber,
      tenantId: doctor.tenantId,
      imageUrl: doctor.imageUrl
    });
    setEditDoctorOpen(true);
  };

  const handleUpdateDoctor = async () => {
    try {
      await axios.put(`http://localhost:5000/doctors/${doctorToEdit.id}`, newDoctor);
      setDoctors(doctors.map(doc => (doc.id === doctorToEdit.id ? { ...doc, ...newDoctor } : doc)));
      setEditDoctorOpen(false);
      setDoctorToEdit(null);
      setNewDoctor({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        specialization: '',
        licenseNumber: '',
        tenantId: '',
        imageUrl: ''
      });
      setSnackbarMessage('Docteur mis à jour avec succès.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Échec de la mise à jour du docteur', error);
      setSnackbarMessage('Erreur lors de la mise à jour du docteur.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleDeleteDoctor = async (doctorId) => {
    try {
      await axios.delete(`http://localhost:5000/doctors/${doctorId}`);
      setDoctors(doctors.filter(doc => doc.id !== doctorId));
      setSnackbarMessage('Docteur supprimé avec succès.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Échec de la suppression du docteur', error);
      setSnackbarMessage('Erreur lors de la suppression du docteur.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarMessage('');
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = `/images/${file.name}`;
      setNewDoctor({ ...newDoctor, imageUrl });
    }
  };

  const specializations = [
    "Orthodontiste",
    "Chirurgien-dentiste",
    "Parodontologue",
    "Endodontiste",
    "Prothésiste dentaire"
  ];
  

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Docteurs
            </Typography>
          
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton
              onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 1 }} />
            {secondaryListItems}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: theme.palette.background.default,
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Box sx={{ p: 3 }}>
            <Grid container spacing={3}>
              {doctors.map((doc) => (
                <Grid item key={doc.id} xs={12} sm={6} md={4} lg={3}>
                  <StyledCard>
                    <CardMedia
                      component="img"
                      image={doc.imageUrl || 'https://via.placeholder.com/200'}
                      title={doc.firstName + ' ' + doc.lastName}
                      sx={{ height: 200 }}
                    />
                    <CardContent>
                      <Typography variant="h6">{doc.firstName} {doc.lastName}</Typography>
                      <Typography variant="body2">{doc.email}</Typography>
                      <Typography variant="body2">{doc.phoneNumber}</Typography>
                      <Typography variant="body2">{doc.specialization}</Typography>
                      <Typography variant="body2">{doc.licenseNumber}</Typography>
                      <Typography variant="body2">Locataire : {tenants.find(tenant => tenant.id === doc.tenantId)?.name}</Typography>
                      <IconButton size="small" onClick={() => handleEditDoctor(doc)}>
                      <EditIcon fontSize="small" style={{ color: theme.palette.primary.main }} />
                      </IconButton>
                      <IconButton size="small" onClick={() => handleDeleteDoctor(doc.id)}>
                      <DeleteIcon fontSize="small" style={{ color: theme.palette.primary.main }} />
                      </IconButton>
                    </CardContent>
                  </StyledCard>
                </Grid>
              ))}
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <StyledCard onClick={() => setAddDoctorOpen(true)}>
                  <CardMedia
                    component="img"
                    image="https://via.placeholder.com/200"
                    title="Ajouter un nouveau docteur"
                    sx={{ height: 200 }}
                  />
                  <CardContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <AddCircleOutlineIcon style={{ fontSize: 50, color: theme.palette.primary.main }} />
                  </CardContent>
                </StyledCard>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      <Dialog open={addDoctorOpen} onClose={() => setAddDoctorOpen(false)}>
        <DialogTitle>Ajouter un nouveau docteur</DialogTitle>
        <DialogContent>
          <TextField
            label="Prénom"
            value={newDoctor.firstName}
            onChange={(e) => setNewDoctor({ ...newDoctor, firstName: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Nom"
            value={newDoctor.lastName}
            onChange={(e) => setNewDoctor({ ...newDoctor, lastName: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Email"
            value={newDoctor.email}
            onChange={(e) => setNewDoctor({ ...newDoctor, email: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Numéro de téléphone"
            value={newDoctor.phoneNumber}
            onChange={(e) => setNewDoctor({ ...newDoctor, phoneNumber: e.target.value })}
            fullWidth
            margin="normal"
          />
         <FormControl fullWidth margin="normal">
  <InputLabel>Spécialisation</InputLabel>
  <Select
    value={newDoctor.specialization}
    onChange={(e) => setNewDoctor({ ...newDoctor, specialization: e.target.value })}
  >
    {specializations.map((specialization) => (
      <MenuItem key={specialization} value={specialization}>
        {specialization}
      </MenuItem>
    ))}
  </Select>
</FormControl>

          <TextField
            label="Numéro de licence"
            value={newDoctor.licenseNumber}
            onChange={(e) => setNewDoctor({ ...newDoctor, licenseNumber: e.target.value })}
            fullWidth
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Locataire</InputLabel>
            <Select
              value={newDoctor.tenantId}
              onChange={(e) => setNewDoctor({ ...newDoctor, tenantId: e.target.value })}
            >
              {tenants.map((tenant) => (
                <MenuItem key={tenant.id} value={tenant.id}>
                  {tenant.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button variant="contained" component="label" sx={{ mt: 2 }}>
            Sélectionner une image
            <input type="file" hidden onChange={handleImageChange} />
          </Button>
          {newDoctor.imageUrl && (
            <Typography variant="body2" sx={{ mt: 2 }}>
              Image sélectionnée : {newDoctor.imageUrl}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddDoctorOpen(false)} color="primary">
            Annuler
          </Button>
          <Button onClick={handleAddDoctor} color="primary">
            Ajouter
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={editDoctorOpen} onClose={() => setEditDoctorOpen(false)}>
        <DialogTitle>Modifier le docteur</DialogTitle>
        <DialogContent>
          <TextField
            label="Prénom"
            value={newDoctor.firstName}
            onChange={(e) => setNewDoctor({ ...newDoctor, firstName: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Nom"
            value={newDoctor.lastName}
            onChange={(e) => setNewDoctor({ ...newDoctor, lastName: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Email"
            value={newDoctor.email}
            onChange={(e) => setNewDoctor({ ...newDoctor, email: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Numéro de téléphone"
            value={newDoctor.phoneNumber}
            onChange={(e) => setNewDoctor({ ...newDoctor, phoneNumber: e.target.value })}
            fullWidth
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
  <InputLabel>Spécialisation</InputLabel>
  <Select
    value={newDoctor.specialization}
    onChange={(e) => setNewDoctor({ ...newDoctor, specialization: e.target.value })}
  >
    {specializations.map((specialization) => (
      <MenuItem key={specialization} value={specialization}>
        {specialization}
      </MenuItem>
    ))}
  </Select>
</FormControl>

          <TextField
            label="Numéro de licence"
            value={newDoctor.licenseNumber}
            onChange={(e) => setNewDoctor({ ...newDoctor, licenseNumber: e.target.value })}
            fullWidth
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Locataire</InputLabel>
            <Select
              value={newDoctor.tenantId}
              onChange={(e) => setNewDoctor({ ...newDoctor, tenantId: e.target.value })}
            >
              {tenants.map((tenant) => (
                <MenuItem key={tenant.id} value={tenant.id}>
                  {tenant.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDoctorOpen(false)} color="primary">
            Annuler
          </Button>
          <Button onClick={handleUpdateDoctor} color="primary">
            Mettre à jour
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
}

export default DoctorConfig;
