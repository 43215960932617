import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import ListIcon from '@mui/icons-material/List';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { mainListItems, secondaryListItems } from './listItems';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { useNavigate } from 'react-router-dom';
import { FaFileAlt } from 'react-icons/fa';
import { useReactToPrint } from 'react-to-print';
import FilterListIcon from '@mui/icons-material/FilterList';
import Switch from '@mui/material/Switch';
import { AiOutlineRobot } from 'react-icons/ai'; // Importiere das AI Icon
import { Checkbox} from '@mui/material';
import Tooltip  from '@mui/material/Tooltip';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment/locale/fr';

import PrintIcon from '@mui/icons-material/Print';


const drawerWidth = 240;





const Prescription = React.forwardRef(({ patient, doctor, tenant, treatments,partner }, ref) => (
  <Paper ref={ref} elevation={3} style={{ padding: '20px', marginTop: '20px', backgroundColor: '#f5f5f5' }}>
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Typography variant="h6" gutterBottom>Ordonnance</Typography>
        <Typography><strong>Nom :</strong> {patient.firstName} {patient.lastName}</Typography>
        <Typography><strong>Clinique :</strong> {tenant.name}</Typography>
        <Typography><strong>Médecin :</strong> Dr. {doctor.firstName} {doctor.lastName}</Typography>
        <Typography><strong>Adresse :</strong> {tenant.address }</Typography>
        <Typography><strong>Assurance Client :</strong> {partner.Name }</Typography>
      </Grid>
      <Grid item xs={12} md={6} container justifyContent="flex-end">
        <Typography variant="h6">Date : {dayjs().format('DD/MM/YYYY')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>Médicaments :</Typography>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th style={{ border: '1px solid #ddd', padding: '8px', width: '100%' }}>Médicament</th>
             
            </tr>
          </thead>
          <tbody>
            {treatments.map((treatment, index) => (
              <tr key={index}>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{treatment.treatment}</td>
               
              </tr>
            ))}
          </tbody>
        </table>
      </Grid>
      <Grid item xs={12} container justifyContent="flex-end">
        <Typography variant="body2" style={{ marginTop: '20px' }}>Signature du médecin</Typography>
      </Grid>
      <Grid item xs={12} style={{ height: '80px' }} /> {/* Platzhalter für den Stempel */}
    </Grid>
  </Paper>
));


const DiagnosisPrint = React.forwardRef(({ patient, doctor, tenant, diagnoses,partner }, ref) => (
  <Paper ref={ref} elevation={3} style={{ padding: '20px', marginTop: '20px', backgroundColor: '#f5f5f5' }}>
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Typography variant="h6" gutterBottom>Diagnostiques</Typography>
        <Typography><strong>Nom :</strong> {patient.firstName} {patient.lastName}</Typography>
        <Typography><strong>Clinique :</strong> {tenant.name}</Typography>
        <Typography><strong>Médecin :</strong> Dr. {doctor.firstName} {doctor.lastName}</Typography>
        <Typography><strong>Adresse :</strong> {tenant.address }</Typography>
        <Typography><strong>Assurance Client :</strong> {partner.Name }</Typography>
      </Grid>
      <Grid item xs={12} md={6} container justifyContent="flex-end">
        <Typography variant="h6">Date : {dayjs().format('DD/MM/YYYY')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>Liste des diagnostiques :</Typography>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th style={{ border: '1px solid #ddd', padding: '8px', width: '100%' }}>Diagnostique</th>
          
            </tr>
          </thead>
          <tbody>
            {diagnoses.map((diagnosis, index) => (
              <tr key={index}>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{diagnosis.diagnosis}</td>
               
              </tr>
            ))}
          </tbody>
        </table>
      </Grid>
      <Grid item xs={12} container justifyContent="flex-end">
        <Typography variant="body2" style={{ marginTop: '20px' }}>Signature du médecin</Typography>
      </Grid>
      <Grid item xs={12} style={{ height: '80px' }} /> {/* Platzhalter für den Stempel */}
    </Grid>
  </Paper>
));

const InvoicePrint = React.forwardRef(({ patient, doctor, tenant, invoiceItems, partner }, ref) => {
  // Berechne totalAmount ohne Berücksichtigung der Partnerteile, wenn der Tenant in "Maroc" ist
  const totalAmount = invoiceItems.reduce((sum, item) => sum + item.amount, 0);
  const isMaroc = tenant.country === 'Maroc'; // Prüfen, ob das Land "Maroc" ist

  // Default values for part_client and part_assurance if partner is undefined
  const partClient = partner ? partner.Client_Part : 0;
  const partAssurance = partner ? partner.Insurance_Part_assurance : 0;

  return (
    <Paper ref={ref} elevation={3} style={{ padding: '20px', marginTop: '20px', backgroundColor: '#f5f5f5' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>Facture</Typography>
          <Typography><strong>Nom :</strong> {patient.firstName} {patient.lastName}</Typography>
          <Typography><strong>Clinique :</strong> {tenant.name}</Typography>
          <Typography><strong>Médecin :</strong> Dr. {doctor.firstName} {doctor.lastName}</Typography>
          <Typography><strong>Adresse :</strong> {tenant.address }</Typography>
          <Typography><strong>Assurance Client :</strong> {partner.Name }</Typography>
        </Grid>
        <Grid item xs={12} md={6} container justifyContent="flex-end">
          <Typography variant="h6">Date : {dayjs().format('DD/MM/YYYY')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>Articles de la facture :</Typography>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th style={{ border: '1px solid #ddd', padding: '8px', width: '20%' }}>Description</th>
                <th style={{ border: '1px solid #ddd', padding: '8px', width: '20%' }}>Prix</th>
                {!isMaroc && (
                  <>
                    <th style={{ border: '1px solid #ddd', padding: '8px', width: '30%' }}>Part Client {partner.Client_Part}%</th>
                    <th style={{ border: '1px solid #ddd', padding: '8px', width: '30%' }}>Part Assurance ({partner.Insurance_Part}%)</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {invoiceItems.map((item, index) => (
                <tr key={index}>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.description}</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.amount.toFixed(2)} MRU</td>
                  {!isMaroc && (
                    <>
                      <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                        {(item.amount * (partner.Client_Part / 100)).toFixed(2)} MRU
                      </td>
                      <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                        {(item.amount * (partner.Insurance_Part / 100)).toFixed(2)} MRU
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{ margin: '20px 0' }} />
        </Grid>
        <Grid item xs={12} container justifyContent="flex-end">
          <Typography variant="h6">Prix Total : {totalAmount.toFixed(2)} {isMaroc ? 'MAD' : 'MRU'}</Typography>
        </Grid>
        {!isMaroc && (
          <>
            <Grid item xs={12} container justifyContent="flex-end">
              <Typography variant="h6">Part Assurance : {(totalAmount * (partner.Insurance_Part / 100)).toFixed(2)} MRU</Typography>
            </Grid>
            <Grid item xs={12} container justifyContent="flex-end">
              <Typography variant="h6">Part Client : {(totalAmount * (partner.Client_Part / 100)).toFixed(2)} MRU</Typography>
            </Grid>
          </>
        )}
        <Grid item xs={12} container justifyContent="flex-end">
          <Typography variant="body2" style={{ marginTop: '20px' }}>Signature du médecin</Typography>
        </Grid>
        <Grid item xs={12} style={{ height: '80px' }} /> {/* Platzhalter für den Stempel */}
      </Grid>
    </Paper>
  );
});



const ConsultationInvoicePrint = React.forwardRef(({ patient, doctor, tenant, consultationItems, partner, appointment }, ref) => {
  const totalAmount = (consultationItems[0].amount);
  console.log("CConcuslttttttt",totalAmount);
  

  return (
    <Paper ref={ref} elevation={3} style={{ padding: '20px', marginTop: '20px', backgroundColor: '#f5f5f5' }}>
      {consultationItems.map((item, index) => (
      <Grid container spacing={2} key={index}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>Facture de Consultation</Typography>
          <Typography><strong>Nom :</strong> {patient.firstName} {patient.lastName}</Typography>
          <Typography><strong>Clinique :</strong> {tenant.name}</Typography>
          <Typography><strong>Médecin :</strong> Dr. {doctor.firstName} {doctor.lastName}</Typography>
          <Typography><strong>Adresse :</strong> {tenant.address }</Typography>
          <Typography><strong>Assurance Client :</strong> {partner.Name }</Typography>
        </Grid>
        <Grid item xs={12} md={6} container justifyContent="flex-end">
          <Typography variant="h6">Date : {dayjs().format('DD/MM/YYYY')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>Articles de la facture :</Typography>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th style={{ border: '1px solid #ddd', padding: '8px', width: '20%' }}>Description</th>
                <th style={{ border: '1px solid #ddd', padding: '8px', width: '20%' }}>Prix Consultation</th>
                <th style={{ border: '1px solid #ddd', padding: '8px', width: '30%' }}>Part Client {item.part_client}%</th>
                <th style={{ border: '1px solid #ddd', padding: '8px', width: '50%' }}>Part Assurance {item.part_assurance}%</th>
              </tr>
            </thead>
            <tbody>
              <tr key={index}>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.description}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                  {totalAmount} MRU
                </td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                  {(totalAmount) * item.part_client / 100} MRU
                </td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                  {(totalAmount) * item.part_assurance / 100} MRU
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{ margin: '20px 0' }} />
        </Grid>
        <Grid item xs={12} container justifyContent="flex-end">
          <Typography variant="h6">Prix Total : {totalAmount} MRU</Typography>
        </Grid>
        <Grid item xs={12} container justifyContent="flex-end">
          <Typography variant="h6">Part Assurance  : {(totalAmount * (partner.Insurance_Part / 100))} MRU</Typography>
        </Grid>
        <Grid item xs={12} container justifyContent="flex-end">
          <Typography variant="h6">Part Client : {(totalAmount * (partner.Client_Part / 100))} MRU</Typography>
        </Grid>
        <Grid item xs={12} container justifyContent="flex-end">
          <Typography variant="body2" style={{ marginTop: '20px' }}>Signature du médecin</Typography>
        </Grid>
        <Grid item xs={12} style={{ height: '80px' }} /> {/* Platzhalter für den Stempel */}
      </Grid>
      ))}
    </Paper>
  );
});

// Setze die Lokalisierung auf Französisch
moment.locale('fr');

const localizer = momentLocalizer(moment);

// Französische Übersetzungen für den Kalender
const messages = {
  allDay: 'Toute la journée',
  previous: 'Précédent',
  next: 'Suivant',
  today: "Aujourd'hui",
  month: 'Mois',
  week: 'Semaine',
  day: 'Jour',
  agenda: 'Agenda',
  date: 'Date',
  time: 'Heure',
  event: 'Événement',
  noEventsInRange: 'Aucun événement prévu dans cette période.',
  showMore: total => `+ ${total} plus`,
};

const AppointmentCalendar = ({ appointments, onSelectSlot }) => {
  // Logge die Termine und ihren Status, um sicherzustellen, dass sie korrekt sind
  console.log("Appointments:", appointments);

  // Events formatieren, um sie im Kalender darzustellen
  const events = Array.isArray(appointments) ? appointments.map(appointment => {
    // Gib den Status des Termins in der Konsole aus
    console.log(`Status of appointment: ${appointment.status}`);

    return {
      title: `Patient: ${appointment.patientName}`,
      start: new Date(appointment.visitDate), // Konvertiere visitDate in JavaScript Date
      end: new Date(new Date(appointment.visitDate).getTime() + 30 * 60000), // Endzeit 30 Minuten nach Start
      status: appointment.status, // Status speichern
      style: {
        backgroundColor: appointment.status === 'Complété' ? 'red' : 'green', // Setze die Farbe basierend auf dem Status
        color: 'white', // Textfarbe
      }
    };
  }) : [];

  // Logge die Events, um sicherzustellen, dass sie korrekt formatiert sind
  console.log("Calendar Events:", events);

  const eventStyleGetter = (event) => {
    const style = {
      backgroundColor: event.style.backgroundColor,
      color: event.style.color,
      borderRadius: '5px',
      border: 'none',
      display: 'block',
    };
    return {
      style: style
    };
  };

  return (
    <div style={{ height: 500 }}>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        defaultView="week"
        views={['month', 'week', 'day']}
        step={30}
        timeslots={2}
        selectable
        onSelectSlot={onSelectSlot}
        eventPropGetter={eventStyleGetter} // Übergebe die Funktion zur Anpassung des Event-Stils
        messages={messages} // Französische Übersetzungen anwenden
        onSelectEvent={(event) => console.log('Selected event: ', event)}
      />
    </div>
  );
};

// Ajouter un intercepteur Axios pour ajouter l'en-tête d'autorisation à chaque demande
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  })
);


const theme = createTheme({
  palette: {
    primary: {
      main: '#22C55E', // Lebendiges Grün für Akzente
    },
    secondary: {
      main: '#A7F3D0', // Weiches, pastelliges Grün für Hintergrundbereiche
    },
    background: {
      default: '#F0FDF4', // Sehr helles Grün für den Haupt-Hintergrund
      paper: '#FFFFFF', // Weiß für Karten und Dialoge
    },
    text: {
      primary: '#065F46', // Dunkler Grünton für Text
      secondary: '#065F46',
    },
  },
  shape: {
    borderRadius: 16, // Sanfte Rundungen für modernes Design
  },
  shadows: [
    'none',
    '0px 4px 12px rgba(0, 0, 0, 0.1)', // Weicher Schatten für Karten und Komponenten
  ],
  typography: {
    fontFamily: 'Arial, sans-serif',
    h6: {
      fontWeight: 700,
    },
  },
});


const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[4],
  padding: theme.spacing(2),
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  backgroundColor: '#e3f2fd',
  borderRadius: '10px 10px 0 0',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.02)',
    boxShadow: theme.shadows[4],
    borderRadius: '10px',
    backgroundColor: '#f5f5f5',
  },
  '&:not(:last-child)': {
    marginBottom: theme.spacing(2),
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function Dashboard() {
  const [open, setOpen] = useState(true);
  const [patients, setPatients] = useState([]);
  const [tenants, setTenants] = useState([]);
  const [partners, setPartners] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [visits, setVisits] = useState([]);
  const [addVisitOpen, setAddVisitOpen] = useState(false);
  const [addPatientOpen, setAddPatientOpen] = useState(false);
  const [viewPatientOpen, setViewPatientOpen] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [deleteSuccessOpen, setDeleteSuccessOpen] = useState(false);
  const [deleteErrorOpen, setDeleteErrorOpen] = useState(false);
  const [editPatientOpen, setEditPatientOpen] = useState(false);
  const [addSuccessOpen, setAddSuccessOpen] = useState(false);
  const [addErrorOpen, setAddErrorOpen] = useState(false);
  const [searchFilter, setSearchFilter] = useState('');
  const [activeButton, setActiveButton] = useState('all');
  const [selected, setSelected] = useState('');
  const [orderBy, setOrderBy] = useState('lastName');
  const [order, setOrder] = useState('asc');
  const [action, setAction] = useState('');
  const [tabIndex, setTabIndex] = useState(0);
  const [editSuccessOpen, setEditSuccessOpen] = useState(false);
  const [editErrorOpen, setEditErrorOpen] = useState(false);
  const [addManagerOpen, setAddManagerOpen] = useState(false);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(null);
  const [appointments, setAppointments] = useState([]);
  const [addAppointmentOpen, setAddAppointmentOpen] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [editAppointmentOpen, setEditAppointmentOpen] = useState(false);
  const [showNotes, setShowNotes] = useState({});
  const [showReason, setShowReason] = useState({});
  const [filterDoctor, setFilterDoctor] = useState('');
  const [diagnoses, setDiagnoses] = useState([]);
  const [treatments, setTreatments] = useState([]);
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [previousAppointments, setPreviousAppointments] = useState([]);
  const [editErrorMessage, setEditErrorMessage] = useState('');
  const [viewAppointmentOpen, setViewAppointmentOpen] = useState(false);
  const [addAppointmentRendezVousOpen, setAddAppointmentRendezVousOpen] = useState(false); // Zustand für den „Ajouter une consultation“-Dialog
  const prescriptionRef = useRef();
  const diagnosisPrintRef = useRef();
  const invoicePrintRef = useRef();
  const consultationInvoicePrintRef = useRef(); // Füge dies hinzu
  const [currentPage, setCurrentPage] = useState(0);
const itemsPerPage = 10; // Anzahl der Patienten, die pro Seite angezeigt werden sollen





  console.log("c3333333333333333333333");
  useEffect(() => {
    const fetchPatients = async () => {
      const response = await axios.get('api/patients');
      setPatients(response.data);
    };

    const fetchTenants = async () => {
      const response = await axios.get('api/tenants');
      setTenants(response.data);
    };

      const fetchPartners = async () => {
        try {
          const response = await axios.get('api/partners');
          console.log("Partners fetched successfully:", response.data); // Debugging line
          setPartners(response.data);
        } catch (error) {
          console.error("Error fetching partners:", error);
        }
      };
  

    const fetchDoctors = async () => {
      const response = await axios.get('api/doctors');
      setDoctors(response.data);
    };

    const fetchVisits = async () => {
      const response = await axios.get('api/visits');
      setVisits(response.data);
    };

    fetchPatients();
    fetchTenants();
    fetchPartners();
    fetchDoctors();
    fetchVisits();
  }, []);

  const navigate = useNavigate(); // Verwende useNavigate zum Weiterleiten


  useEffect(() => {
    // Axios-Interceptor zur automatischen Hinzufügung des Tokens zu jeder Anfrage
    const requestInterceptor = axios.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem('token'); // Token aus dem lokalen Speicher abrufen
        if (token) {
          config.headers.Authorization = `Bearer ${token}`; // Token in den Authorization-Header setzen
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    // Axios-Interceptor zur Abfangung von abgelaufenen Token
    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        // Erfolgreiche Antwort - kein Handlungsbedarf
        return response;
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          // Überprüfen, ob der Fehler auf einen abgelaufenen Token hinweist
          if (error.response.data.error === 'Token abgelaufen') {
            // Token aus dem lokalen Speicher entfernen
            localStorage.removeItem('token');
            // Redirect zur Login-Seite mit useNavigate
            navigate('/login');
          }
        }
        return Promise.reject(error);
      }
    );

    // Cleanup function to eject the interceptors when the component unmounts
    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [navigate]); // Abhängigkeit auf navigate setzen

  const handleAddAppointmentRendezVousOpen = (patient) => {
    setAddAppointmentRendezVousOpen(true);
    setSelectedPatient({
      ...patient,
      dateOfBirth: dayjs(patient.dateOfBirth),
      created_at: dayjs(patient.created_at),
    });
};

const handleAddAppointmentRendezVous = async (newAppointment) => {
  try {
      const response = await axios.post('api/visits', newAppointment);
      setAppointments((prevAppointments) => [...prevAppointments, response.data]);
      setAddAppointmentRendezVousOpen(false);
      setAddSuccessOpen(true); // Erfolgsnachricht anzeigen
  } catch (error) {
      console.error('Failed to add appointment', error);
      setAddAppointmentRendezVousOpen(false);
      setAddErrorOpen(true); // Fehlermeldung anzeigen
  }
};
  useEffect(() => {
    const patient = patients.find(pat => pat.id === selected);
    if (action === 'view' && selected) {
      const patient = patients.find(pat => pat.id === selected);
      handleViewPatientOpen(patient);
      console.log("viwwwwwwwww",action,);
    } else if (action === 'consultation' && selected) {
      const patient = patients.find(pat => pat.id === selected);
    
      console.log("selectedPatient", selectedPatient );
      handleAddAppointmentOpen(patient);
    }else if (action === 'rendezvous' && selected) {
      const patient = patients.find(pat => pat.id === selected);
    
      console.log("selectedPatient", selectedPatient );
      handleAddAppointmentRendezVousOpen(patient);
    }else if (action === 'edit' && selected) {
      const patient = patients.find(pat => pat.id === selected);
      handleEditPatientOpen(patient);
      console.log("viwwwwwwwww",action,);
    } else if (action === 'delete') {
      setDeleteConfirmOpen(true);
    }
    setAction('');
  }, [action, selected, patients]);



  const handleAddAppointmentOpen = (patient) => {
    setAddAppointmentOpen(true);
    setSelectedPatient({
      ...patient,
      dateOfBirth: dayjs(patient.dateOfBirth),
      created_at: dayjs(patient.created_at),
    });
  };

  const handleAddAppointmentrendezvousOpen = (patient) => {

  };

  const handleEditAppointmentOpen = async (appointment) => {
    setSelectedAppointment({
      ...appointment,
      visitDate: dayjs(appointment.visitDate),
      created_at: dayjs(appointment.created_at),
    });
  
    // Fetch diagnoses and treatments independently
    try {
      const diagnosesResponse = await axios.get(`api/diagnoses?visitId=${appointment.id}`);
      setDiagnoses(diagnosesResponse.data || []);
  
      const treatmentsResponse = await axios.get(`api/treatments?visitId=${appointment.id}`);
      setTreatments(treatmentsResponse.data || []);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  
    // Fetch invoice items separately
    try {
      const invoicesResponse = await axios.get(`api/invoices?visitId=${appointment.id}`);
      setInvoiceItems(invoicesResponse.data.items || []);
      setTotalAmount(invoicesResponse.data.totalAmount || 0);
    } catch (error) {
      console.error('Failed to fetch invoices:', error);
    }
  
    setEditAppointmentOpen(true);
  };

  const handleAddAppointmentClose = () => {
    setAddAppointmentOpen(false);
  };

  const handleAddAppointmentRendezVousClose = () => {
    setAddAppointmentOpen(false);
  };
  const handleEditAppointmentClose = () => {
    setEditAppointmentOpen(false);
    setDiagnoses([]);
    setTreatments([]);
    setInvoiceItems([]);
    setTotalAmount(0);
  };

  const handleAddAppointment = async (newAppointment) => {
    try {
      const response = await axios.post('api/visits', newAppointment);
      setAppointments((prevAppointments) => [...prevAppointments, response.data]);
      setAddAppointmentOpen(false);
      setAddSuccessOpen(true);
      
     
      // PayPeriod in der Konsole ausgeben
      if (response.data.payPeriod !== undefined) {
        console.log(`Die payPeriod für diesen Besuch beträgt: ${response.data.payPeriod} Tage.`);
      }
    } catch (error) {
      console.error('Failed to add appointment', error);
      setAddAppointmentOpen(false);
      setAddErrorOpen(true);
    }
  };
  
  const handleEditAppointment = async (updatedAppointment) => {
    if (updatedAppointment.status === 'complété') {
      // Wenn der Status auf "complété" steht, das Bearbeiten verhindern
      setEditErrorMessage('');
      setEditErrorOpen(true);
      return;
    }
  
    // Verhindern der Änderung von visitDate, wenn checkIn auf "Arrivé" gesetzt ist
    const originalAppointment = appointments.find(app => app.id === updatedAppointment.id);
    if (originalAppointment?.checkIn === 'Arrivé' && !dayjs(originalAppointment.visitDate).isSame(updatedAppointment.visitDate)) {
      setEditErrorMessage('');
      setEditErrorOpen(true);
      return;
    }
  
    try {
      const response = await axios.put(`api/visits/${updatedAppointment.id}`, updatedAppointment);
      setAppointments((prevAppointments) =>
        prevAppointments.map((appointment) =>
          appointment.id === updatedAppointment.id ? response.data : appointment
        )
      );
      setEditAppointmentOpen(false);
      setEditSuccessOpen(true);
    } catch (error) {
      console.error('Failed to update appointment', error);
  
      // Setzen der Fehlermeldung aus dem Backend
      setEditErrorMessage(error.response?.data?.error || 'Échec de la mise à jour du rendez-vous');
  
      setEditAppointmentOpen(false);
      setEditErrorOpen(true);
    }
  };
  
  const handleNewAppointmentOpen = async (appointment) => {
    setSelectedAppointment({
      ...appointment,
      visitDate: dayjs(appointment.visitDate),
      created_at: dayjs(appointment.created_at),
    });
  
    // Fetch diagnoses and treatments independently
    try {
      const diagnosesResponse = await axios.get(`api/diagnoses?visitId=${appointment.id}`);
      setDiagnoses(diagnosesResponse.data || []);
  
      const treatmentsResponse = await axios.get(`api/treatments?visitId=${appointment.id}`);
      setTreatments(treatmentsResponse.data || []);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  
    // Fetch invoice items separately
    try {
      const invoicesResponse = await axios.get(`api/invoices?visitId=${appointment.id}`);
      setInvoiceItems(invoicesResponse.data.items || []);
      setTotalAmount(invoicesResponse.data.totalAmount || 0);
    } catch (error) {
      console.error('Failed to fetch invoices:', error);
    }
  
    setTabIndex(0);
    setViewAppointmentOpen(true);
  };

    const handleViewAppointmentOpen = async (appointment) => {
    setSelectedAppointment({
      ...appointment,
      visitDate: dayjs(appointment.visitDate),
      created_at: dayjs(appointment.created_at),
    });
  
    // Fetch diagnoses and treatments independently
    try {
      const diagnosesResponse = await axios.get(`api/diagnoses?visitId=${appointment.id}`);
      setDiagnoses(diagnosesResponse.data || []);
  
      const treatmentsResponse = await axios.get(`api/treatments?visitId=${appointment.id}`);
      setTreatments(treatmentsResponse.data || []);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  
    // Fetch invoice items separately
    try {
      const invoicesResponse = await axios.get(`api/invoices?visitId=${appointment.id}`);
      setInvoiceItems(invoicesResponse.data.items || []);
      setTotalAmount(invoicesResponse.data.totalAmount || 0);
    } catch (error) {
      console.error('Failed to fetch invoices:', error);
    }
  
    setTabIndex(0);
    setViewAppointmentOpen(true);
  };

  const handleViewAppointmentClose = () => {
    setViewAppointmentOpen(false);
    setDiagnoses([]);
    setTreatments([]);
    setInvoiceItems([]);
    setTotalAmount(0);
  };

  const handleAppointmentClick = (id) => {
    setSelected(id);
  };

  const isSelected = (id) => selected === id;

  const handleDeleteAppointment = async () => {
    try {
      await axios.delete(`api/visits/${selected}`);
      setAppointments((prevAppointments) =>
        prevAppointments.filter((appointment) => appointment.id !== selected)
      );
      setSelected('');
      setDeleteConfirmOpen(false);
      setDeleteSuccessOpen(true);
    } catch (error) {
      console.error('Failed to delete appointment', error);
      setDeleteConfirmOpen(false);
      setDeleteErrorOpen(true);
    }
  };

  const handleSearchChange = (event) => {
    setSearchFilter(event.target.value);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleActionChange = (event) => {
    setAction(event.target.value);
    console.log("chengeeeeee",action)
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleStatusChange = async (id, status) => {
    const appointment = appointments.find(app => app.id === id);

    if (status === 'complété' && appointment.checkIn !== 'Arrivé') {
      alert('Le patient doit d\'abord être marqué comme Arrivé.');
      return;
    }

    try {
      const response = await axios.put(`api/visits/${id}`, { status });
      setAppointments((prevAppointments) =>
        prevAppointments.map((appointment) =>
          appointment.id === id ? response.data : appointment
        )
      );
    } catch (error) {
      console.error('Failed to update status', error);
    }
  };

  const handleCheckInChange = async (id, checkIn) => {
    try {
      const response = await axios.put(`api/visits/${id}`, { checkIn });
      setAppointments((prevAppointments) =>
        prevAppointments.map((appointment) =>
          appointment.id === id ? response.data : appointment
        )
      );
    } catch (error) {
      console.error('Failed to update check-in', error);
    }
  };

  const handleFilterChange = (filter) => {
    setActiveButton(filter);
  };



  


  const toggleNotesVisibility = (id) => {
    setShowNotes((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const toggleReasonVisibility = (id) => {
    setShowReason((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const getBorderColor = (appointment, doctor) => {
    if (doctor) {
      return theme.palette.primary.main;
    }
    return appointment.checkIn === 'Arrivé' ? 'green' : 'red';
  };

  const handlePrintDiagnosis = useReactToPrint({
    content: () => diagnosisPrintRef.current,
  });

  const handlePrintPrescription = useReactToPrint({
    content: () => prescriptionRef.current,
  });

  const handlePrintInvoice = useReactToPrint({
    content: () => invoicePrintRef.current,
  });



  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleAddPatientOpen = () => {
    setAddPatientOpen(true);
  };

  const handleEditPatientOpen = (patient) => {
    setSelectedPatient({
      ...patient,
      dateOfBirth: dayjs(patient.dateOfBirth),
      created_at: dayjs(patient.created_at),
    });
    setEditPatientOpen(true);
  };

  const handleAddPatientClose = () => {
    setAddPatientOpen(false);
  };

  const handleEditPatientClose = () => {
    setEditPatientOpen(false);
  };

  const handleAddPatient = async (newPatient) => {
    try {
      const response = await axios.post('api/patients', newPatient);
      setPatients((prevPatients) => [...prevPatients, response.data]);
      setAddPatientOpen(false);
      setAddSuccessOpen(true);
    } catch (error) {
      console.error('Failed to add patient', error);
      setAddPatientOpen(false);
      setAddErrorOpen(true);
    }
  };

  const handleEditPatient = async (updatedPatient) => {
    try {
      const response = await axios.put(`api/patients/${updatedPatient.id}`, updatedPatient);
      setPatients((prevPatients) =>
        prevPatients.map((patient) =>
          patient.id === updatedPatient.id ? response.data : patient
        )
      );
      setEditPatientOpen(false);
      setEditSuccessOpen(true);
    } catch (error) {
      console.error('Failed to update patient', error);
      setEditPatientOpen(false);
      setEditErrorOpen(true);
    }
  };

  

  
  const handleViewPatientOpen = (patient) => {
    setSelectedPatient({
      ...patient,
      dateOfBirth: dayjs(patient.dateOfBirth),
      created_at: dayjs(patient.created_at),
    });
    setTabIndex(1);
    setViewPatientOpen(true);
  };

  const handleViewPatientClose = () => {
    setViewPatientOpen(false);
  };

  const handlePatientClick = (id) => {
    setSelected(id);
  };



  const handleDeletePatient = async () => {
    try {
      await axios.delete(`api/patients/${selected}`);
      setPatients((prevPatients) =>
        prevPatients.filter((patient) => patient.id !== selected)
      );
      setSelected('');
      setDeleteConfirmOpen(false);
      setDeleteSuccessOpen(true);
    } catch (error) {
      console.error('Failed to delete patient', error);
      setDeleteConfirmOpen(false);
      setDeleteErrorOpen(true);
    }
  };


  const handleAddManagerOpen = (departmentId) => {
    setSelectedDepartmentId(departmentId);
    setAddManagerOpen(true);
  };

  const handleAddManagerClose = () => {
    setAddManagerOpen(false);
    setSelectedDepartmentId(null);
  };

  const handleAddManager = async (departmentId, managerId) => {
    try {
      await axios.post(`/departments/${departmentId}/managers`, { managerId });
    } catch (error) {
      console.error('Failed to add manager', error);
    }
  };

  const handleAddVisitOpen = () => {
    setAddVisitOpen(true);
  };

  const handleAddVisitClose = () => {
    setAddVisitOpen(false);
  };

  const handleAddVisit = async (newVisit) => {
    try {
      const response = await axios.post('api/visits', newVisit);
      setVisits((prevVisits) => [...prevVisits, response.data]);
      setAddVisitOpen(false);
    } catch (error) {
      console.error('Failed to add visit', error);
      setAddVisitOpen(false);
    }
  };

  const filteredPatients = patients.filter((patient) => {
    if (activeButton === 'present') return patient.status === 'anwesend';
    if (activeButton === 'absent') return patient.status === 'abwesend';
    return true;
  });

  
  const handleLoadMore = () => {
    if ((currentPage + 1) * itemsPerPage < sortedPatients.length) {
      setCurrentPage(currentPage + 1);
    }
  };

// Filtere die Patientenliste basierend auf dem Suchfeld
const searchedPatients = patients.filter((patient) => {
  const lastName = patient.lastName ? patient.lastName.toLowerCase() : '';
  const firstName = patient.firstName ? patient.firstName.toLowerCase() : '';
  const patientNumber = patient.patientNumber ? patient.patientNumber.toString().toLowerCase() : '';
  const role = patient.role ? patient.role.toLowerCase() : '';
  const email = patient.email ? patient.email.toLowerCase() : '';
  const phoneNumber = patient.phoneNumber ? patient.phoneNumber.toString().toLowerCase() : '';

  return (
    lastName.includes(searchFilter.toLowerCase()) ||
    firstName.includes(searchFilter.toLowerCase()) ||
    patientNumber.includes(searchFilter.toLowerCase()) ||
    role.includes(searchFilter.toLowerCase()) ||
    email.includes(searchFilter.toLowerCase()) ||
    phoneNumber.includes(searchFilter.toLowerCase())
  );
});


// Sortiere die gefilterten Patienten
const sortedPatients = searchedPatients.sort((a, b) => {
  if (order === 'asc') {
    return a[orderBy] < b[orderBy] ? -1 : 1;
  } else {
    return a[orderBy] > b[orderBy] ? -1 : 1;
  }
});

// Paginiere die sortierten und gefilterten Patienten
const paginatedPatients = sortedPatients.slice(
  currentPage * itemsPerPage,
  (currentPage + 1) * itemsPerPage
);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar sx={{ pr: '24px' }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Gestion des patients
            </Typography>
           
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 4 }} />
            {secondaryListItems}
            <Divider sx={{ my: 4 }} />
          </List>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: 5 }}>
          <Toolbar />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, flexWrap: 'wrap' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <TextField
                label="Rechercher"
                variant="outlined"
                size="small"
                value={searchFilter}
                onChange={handleSearchChange}
                sx={{
                  bgcolor: 'white',
                  backgroundColor: '#e3f2fd',
                  borderRadius: '15px',
                  boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2)',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '25px',
                    '& fieldset': {
                      borderWidth: '2px',
                      borderColor: '#2196f3',
                    },
                    '&:hover fieldset': {
                      borderWidth: '2px',
                      borderColor: '#1976d2',
                    },
                    '&.Mui-focused fieldset': {
                      borderWidth: '2px',
                      borderColor: '#1976d2',
                    },
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ color: '#1976d2' }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            
              
          
              <IconButton onClick={handleAddPatientOpen}>
                <AddCircleIcon sx={{ color: theme.palette.primary.main }} />
              </IconButton>
             
              <Select
  value={action}
  onChange={handleActionChange}
  displayEmpty
  sx={{
    ml: 2,
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    borderRadius: '8px', // Abrundung für modernes Aussehen
    border: `1px solid ${theme.palette.primary.main}`, // Rahmen mit Primärfarbe
    '& .MuiSelect-select': {
      padding: '10px 16px', // Interner Abstand
      backgroundColor: theme.palette.background.paper, // Hintergrundfarbe für bessere Sichtbarkeit
    },
    '&:hover': {
      borderColor: theme.palette.secondary.main, // Rahmenfarbe bei Hover
    },
    '&.Mui-focused': {
      borderColor: theme.palette.primary.dark, // Rahmenfarbe bei Fokus
    },
  }}
  MenuProps={{
    PaperProps: {
      sx: {
        borderRadius: '8px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Schatten für Dropdown-Menü
      },
    },
  }}
>
  <MenuItem value="" disabled sx={{ fontWeight: 'bold', color: theme.palette.text.secondary }}>
    Choisir une action
  </MenuItem>
  <MenuItem value="consultation" disabled={!selected} sx={{ fontWeight: 'bold', color: selected ? theme.palette.text.primary : theme.palette.action.disabled }}>
    Ajouter une consultation
  </MenuItem>
  <MenuItem value="view" disabled={!selected} sx={{ fontWeight: 'bold', color: selected ? theme.palette.text.primary : theme.palette.action.disabled }}>
    Historique de consultations
  </MenuItem>
  <MenuItem value="rendezvous" disabled={!selected} sx={{ fontWeight: 'bold', color: selected ? theme.palette.text.primary : theme.palette.action.disabled }}>
    Ajouter un Rendez-Vous
  </MenuItem>
  <MenuItem value="edit" disabled={!selected} sx={{ fontWeight: 'bold', color: selected ? theme.palette.text.primary : theme.palette.action.disabled }}>
    Éditer le patient
  </MenuItem>
  <MenuItem value="delete" disabled={!selected} sx={{ fontWeight: 'bold', color: selected ? theme.palette.error.main : theme.palette.action.disabled }}>
    Supprimer le Patient
  </MenuItem>
</Select>

            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, flexWrap: 'wrap' }}>
            <Typography variant="body1" component="div" sx={{ textAlign: 'right', fontWeight: 'bold', marginBottom: 2, color: theme.palette.primary.dark }}>
              Patients: {patients.length}
            </Typography>
          </Box>
          <StyledTableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell padding="checkbox">
                    <RadioGroup
                      name="patientSelection"
                      value={selected}
                      onChange={(e) => handlePatientClick(e.target.value)}
                    >
                      <FormControlLabel
                        value="all"
                        control={<Radio color="primary" />}
                        label=""
                      />
                    </RadioGroup>
                  </StyledTableCell>
                  <StyledTableCell>Image</StyledTableCell>
              
                  <StyledTableCell>
                    <TableSortLabel
                      active={orderBy === 'firstName'}
                      direction={orderBy === 'firstName' ? order : 'asc'}
                      onClick={() => handleRequestSort('firstName')}
                    >
                      Nom
                    </TableSortLabel>
                  </StyledTableCell>

                  {/* Spalte NAchname */}
               {/*}
                  <StyledTableCell>
                    <TableSortLabel
                      active={orderBy === 'lastName'}
                      direction={orderBy === 'lastName' ? order : 'asc'}
                      onClick={() => handleRequestSort('lastName')}
                    >
                      Nom
                    </TableSortLabel>
                  </StyledTableCell>

               */}
            {/*      <StyledTableCell>Date de naissance</StyledTableCell>        */}
                  <StyledTableCell>Sexe</StyledTableCell>
             {/*     <StyledTableCell>Email</StyledTableCell>          */}
                  <StyledTableCell>Numéro de téléphone</StyledTableCell>
        {/*          <StyledTableCell>Adresse</StyledTableCell>               */}
        {/*          <StyledTableCell>Numéro d'assurance</StyledTableCell>    */}
                  <StyledTableCell>Date de création</StyledTableCell>
                  <StyledTableCell>Mandataire</StyledTableCell>
                  <StyledTableCell>Assurance</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedPatients.map((patient) => {
                  const isItemSelected = isSelected(patient.id);
                  const tenantName = tenants.find(tenant => tenant.id === patient.tenantId)?.name || 'Inconnu';
                  const partnerName = partners.find(partner => partner.id === patient.partnerId)?.name || 'Inconnu';
                  return (
                    <StyledTableRow
                      hover
                      onClick={() => handlePatientClick(patient.id)}
                      role="radio"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={patient.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Radio
                          color="primary"
                          checked={isItemSelected}
                          onChange={() => handlePatientClick(patient.id)}
                          value={patient.id}
                        />
                      </TableCell>
                      <TableCell>
                        <Avatar
                          src={patient.imageUrl}
                          alt={patient.firstName}
                          sx={{
                            width: 60,
                            height: 60,
                          }}
                        />
                      </TableCell>
                      <TableCell>{patient.firstName}</TableCell>
                {/*      <TableCell>{patient.lastName}</TableCell>   */}
                {/*      <TableCell>{dayjs(patient.dateOfBirth).format('DD/MM/YYYY')}</TableCell>    */}
                      <TableCell>{patient.gender}</TableCell>
               {/*       <TableCell>{patient.email}</TableCell>    */}
                      <TableCell>{patient.phoneNumber}</TableCell>
                {/*      <TableCell>{patient.address}</TableCell>   */}
                {/*      <TableCell>{patient.insuranceNumber}</TableCell>   */}
                      <TableCell>{dayjs(patient.created_at).format('DD/MM/YYYY')}</TableCell>
                      <TableCell>{tenantName}</TableCell>
                      <TableCell>{partners.find(partner => partner.id === patient.partnerId)?.Name || 'Inconnu'}</TableCell>

                    </StyledTableRow>
                    
                  );
                })}
              </TableBody>
            </Table>
          </StyledTableContainer>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
  {((currentPage + 1) * itemsPerPage < sortedPatients.length) && (
    <Button variant="contained" color="primary" onClick={handleLoadMore}>
      Mehr laden
    </Button>
  )}
</Box>

          
              <AddRendezvousDialog
                open={addAppointmentRendezVousOpen}
                onClose={handleAddAppointmentClose}
                onAddAppointment={handleAddAppointmentRendezVous}
                patients={patients}
                gewaehlerpatient={selectedPatient}
                partners={partners}
                doctors={doctors}
                tenants={tenants}
                visits={visits}
                
              />
            <AddAppointmentDialog
                open={addAppointmentOpen}
                onClose={handleAddAppointmentClose}
                onAddAppointment={handleAddAppointment}
                patients={patients}
                gewaehlerpatient={selectedPatient}
                partners={partners}
                doctors={doctors}
                tenants={tenants}
                visits={visits}
                
              />
          <AddPatientDialog
            open={addPatientOpen}
            onClose={handleAddPatientClose}
            onAddPatient={handleAddPatient}
            tenants={tenants}
            partners={partners} // Pass partners to AddPatientDialog
          />
          {selectedPatient && (
            <>
              <ViewPatientDialog
                open={viewPatientOpen}
                onClose={handleViewPatientClose}
                patient={selectedPatient}
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}
                patients={patients}
                tenants={tenants}
                partners={partners} // Pass partners to ViewPatientDialog
              />
              <EditPatientDialog
                open={editPatientOpen}
                onClose={handleEditPatientClose}
                patient={selectedPatient}
                onEditPatient={handleEditPatient}
                tenants={tenants}
                partners={partners} // Pass partners to EditPatientDialog
              />
            </>
          )}
 
          <Dialog
            open={deleteConfirmOpen}
            onClose={() => setDeleteConfirmOpen(false)}
          >
            <DialogTitle>Confirmer la suppression</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Êtes-vous sûr de vouloir supprimer le patient sélectionné ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDeleteConfirmOpen(false)} color="primary">
                Annuler
              </Button>
              <Button onClick={handleDeletePatient} color="primary">
                Supprimer
              </Button>
            </DialogActions>
          </Dialog>
          <Snackbar
            open={deleteSuccessOpen}
            autoHideDuration={6000}
            onClose={() => setDeleteSuccessOpen(false)}
          >
            <Alert onClose={() => setDeleteSuccessOpen(false)} severity="success">
              Patient supprimé avec succès !
            </Alert>
          </Snackbar>
          <Snackbar
            open={deleteErrorOpen}
            autoHideDuration={6000}
            onClose={() => setDeleteErrorOpen(false)}
          >
            <Alert onClose={() => setDeleteErrorOpen(false)} severity="error">
              Erreur lors de la suppression du patient !
            </Alert>
          </Snackbar>
          <Snackbar
            open={addSuccessOpen}
            autoHideDuration={6000}
            onClose={() => setAddSuccessOpen(false)}
          >
            <Alert onClose={() => setAddSuccessOpen(false)} severity="success">
              Patient ajouté avec succès !
            </Alert>
          </Snackbar>
          <Snackbar
            open={addErrorOpen}
            autoHideDuration={6000}
            onClose={() => setAddErrorOpen(false)}
          >
            <Alert onClose={() => setAddErrorOpen(false)} severity="error">
              Erreur lors de l'ajout du patient !
            </Alert>
          </Snackbar>
          <Snackbar
            open={editSuccessOpen}
            autoHideDuration={6000}
            onClose={() => setEditSuccessOpen(false)}
          >
            <Alert onClose={() => setEditSuccessOpen(false)} severity="success">
              Patient mis à jour avec succès !
            </Alert>
          </Snackbar>
          <Snackbar
            open={editErrorOpen}
            autoHideDuration={6000}
            onClose={() => setEditErrorOpen(false)}
          >
            <Alert onClose={() => setEditErrorOpen(false)} severity="error">
              Erreur lors de la mise à jour du patient !
            </Alert>
          </Snackbar>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

const AddPatientDialog = ({ open, onClose, onAddPatient }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState(dayjs());
  const [gender, setGender] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [insuranceNumber, setInsuranceNumber] = useState('');
  const [imageName, setImageName] = useState('');
  const [tenantId, setTenantId] = useState('');
  const [partnerId, setPartnerId] = useState('');
  const [tenants, setTenants] = useState([]);
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    const fetchTenants = async () => {
      try {
        const response = await axios.get('api/tenants');
        setTenants(response.data);
      } catch (error) {
        console.error('Failed to fetch tenants', error);
      }
    };
    
    const fetchPartners = async () => {
      try {
        const response = await axios.get('api/partners');
        setPartners(response.data);
      } catch (error) {
        console.error('Failed to fetch partners', error);
      }
    };

    fetchTenants();
    fetchPartners();
  }, []);

  useEffect(() => {
    if (tenants.length > 0) {
      setTenantId(tenants[0].id); // Setzt den ersten Mandanten als Standardwert
    }
    if (partners.length > 0) {
      setPartnerId(partners[0].id); // Setzt den ersten Partner als Standardwert
    }
  }, [tenants, partners]); // Führt den Effekt aus, wenn sich die Listen der Mandanten oder Partner änderndert

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const imageUrl = `/images/${imageName}`;
      const newPatient = {
        firstName,
        lastName,
        dateOfBirth: dateOfBirth.toISOString(),
        gender,
        email,
        phoneNumber,
        address,
        insuranceNumber,
        imageUrl,
        tenantId,
        partnerId,
      };
      await onAddPatient(newPatient);
      handleClose(); // Formular leeren nach dem Hinzufügen
      if (tenants.length > 0) {
        setTenantId(tenants[0].id); // Setzt den ersten Mandanten wieder als Standardwert
      }
      if (partners.length > 0) {
        setPartnerId(partners[0].id); // Setzt den ersten Partner wieder als Standardwert
      }
    } catch (error) {
      console.error('Failed to add patient', error);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageName(file.name);
    }
  };

  const handleClose = () => {
    setFirstName('');
    setLastName('');
    setDateOfBirth(dayjs());
    setGender('');
    setEmail('');
    setPhoneNumber('');
    setAddress('');
    setInsuranceNumber('');
    setImageName('');
    setTenantId(tenants[0]?.id || ''); // Setzt den ersten Mandanten als Standardwert zurück

    setPartnerId(partners[0]?.id || ''); // Setzt den ersten Partner als Standardwert zurück
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>Ajouter un patient</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                autoFocus
                margin="dense"
                label="Nom"
                fullWidth
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Grid>

            {/*Nachname*/}
        {/*
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                label="Nom"
                fullWidth
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Grid>
        */}  


          {/*Geburtsdatum*/}
         {/*
           <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date de naissance"
                  value={dateOfBirth}
                  onChange={(newValue) => setDateOfBirth(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth margin="dense" />}
                />
              </LocalizationProvider>
            </Grid>

            */}

            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="dense" required>
                <InputLabel>Sexe</InputLabel>
                <Select
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <MenuItem value="Femme">Femme</MenuItem>
                  <MenuItem value="Homme">Homme</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/*EMAIL*/}

        {/*
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                label="Email"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>

        */}

                

            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                label="Numéro de téléphone"
                fullWidth
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
              />
            </Grid>



            {/*Adresse*/}

        {/*    
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                label="Adresse"
                fullWidth
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </Grid>
        */}

        {/*Versicherungsnummer*/}

      {/*
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                label="Numéro d'assurance"
                fullWidth
                value={insuranceNumber}
                onChange={(e) => setInsuranceNumber(e.target.value)}
              />
            </Grid>
        
      */}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="dense" required>
                <InputLabel>Mandataire</InputLabel>
                <Select
                  value={tenantId}
                  onChange={(e) => setTenantId(e.target.value)}
                >
            {tenants.length > 0 && tenants.map((tenant) => (

                    <MenuItem key={tenant.id} value={tenant.id}>
                      {tenant.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="dense" required>
                <InputLabel>Partenaire</InputLabel>
                <Select
                  value={partnerId}
                  onChange={(e) => setPartnerId(e.target.value)}
                >
                   {partners.length > 0 && partners.map((partner) => (
                    <MenuItem key={partner.id} value={partner.id}>
                      {partner.Name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="raised-button-file"
                type="file"
                onChange={handleImageChange}
              />
              <label htmlFor="raised-button-file">
                <Button variant="contained" component="span">
                  Sélectionner une image
                </Button>
              </label>
              {imageName && (
                <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                  Fichier sélectionné: {imageName}
                </Typography>
              )}
            </Grid>
          </Grid>
          <DialogActions>
            <Button onClick={handleClose}>Annuler</Button>
            <Button type="submit" color="primary">
              Ajouter
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};


// Innerhalb von EditPatientDialog
function EditPatientDialog({ open, onClose, patient, onEditPatient, tenants, partners }) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState(dayjs());
  const [gender, setGender] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [insuranceNumber, setInsuranceNumber] = useState('');
  const [imageName, setImageName] = useState('');
  const [tenantId, setTenantId] = useState('');
  const [partnerId, setPartnerId] = useState('');

  useEffect(() => {
    if (patient) {
      setFirstName(patient.firstName || '');
      setLastName(patient.lastName || '');
      setDateOfBirth(dayjs(patient.dateOfBirth || new Date()));
      setGender(patient.gender || '');
      setEmail(patient.email || '');
      setPhoneNumber(patient.phoneNumber || '');
      setAddress(patient.address || '');
      setInsuranceNumber(patient.insuranceNumber || '');
      setImageName(patient.imageUrl || '');
      setTenantId(patient.tenantId || '');
      setPartnerId(patient.partnerId || '');
    }
  }, [patient]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedPatient = {
      ...patient,
      firstName,
      lastName,
      dateOfBirth: dateOfBirth.toISOString(),
      gender,
      email,
      phoneNumber,
      address,
      insuranceNumber,
      imageUrl: `/images/${imageName}`,
      tenantId,
      partnerId,
    };

    onEditPatient(updatedPatient);
    handleClose(); // Leert das Formular nach dem Schließen
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageName(file.name);
    }
  };

  const handleClose = () => {
    setFirstName('');
    setLastName('');
    setDateOfBirth(dayjs());
    setGender('');
    setEmail('');
    setPhoneNumber('');
    setAddress('');
    setInsuranceNumber('');
    setImageName('');
    setTenantId('');
    setPartnerId('');
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>Éditer un patient</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                autoFocus
                margin="dense"
                label="Nom"
                fullWidth
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </Grid>

        {/* 
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                label="Nom"
                fullWidth
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </Grid>
        */}

        {/*
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date de naissance"
                  value={dateOfBirth}
                  onChange={(newValue) => setDateOfBirth(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth margin="dense" required />}
                />
              </LocalizationProvider>
            </Grid>
        */}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="dense" required>
                <InputLabel>Sexe</InputLabel>
                <Select
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <MenuItem value="Femme">Femme</MenuItem>
                  <MenuItem value="Homme">Homme</MenuItem>
                </Select>
              </FormControl>
            </Grid>
        {/*
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                label="Email"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
        */}
          <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                label="Numéro de téléphone"
                fullWidth
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
              />
            </Grid>

        {/*
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                label="Adresse"
                fullWidth
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </Grid>
        */}
      
      {/*
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                label="Numéro d'assurance"
                fullWidth
                value={insuranceNumber}
                onChange={(e) => setInsuranceNumber(e.target.value)}
              />
            </Grid>
      */}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="dense" required>
                <InputLabel>Mandataire</InputLabel>
                <Select
                  value={tenantId}
                  onChange={(e) => setTenantId(e.target.value)}
                >
                  {tenants.map((tenant) => (
                    <MenuItem key={tenant.id} value={tenant.id}>
                      {tenant.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="dense" required>
                <InputLabel>Partenaire</InputLabel>
                <Select
                  value={partnerId}
                  onChange={(e) => setPartnerId(e.target.value)}
                >
                  {partners.map((partner) => (
                    <MenuItem key={partner.id} value={partner.id}>
                      {partner.Name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="raised-button-file"
                type="file"
                onChange={handleImageChange}
              />
              <label htmlFor="raised-button-file">
                <Button variant="contained" component="span">
                  Sélectionner une image
                </Button>
              </label>
              {imageName && (
                <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                  Fichier sélectionné: {imageName}
                </Typography>
              )}
            </Grid>
          </Grid>
          <DialogActions>
            <Button onClick={handleClose}>Annuler</Button>
            <Button type="submit" color="primary">
              Enregistrer
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}


const AddRendezvousDialog = ({ 
  open, 
  onClose, 
  onAddAppointment, 
  patients, 
  gewaehlerpatient,
  doctors, 
  tenants,
  partners,
  visits }) => {
  const [patientId, setPatientId] = useState('');
  const [doctorId, setDoctorId] = useState('');
  const [visitDate, setVisitDate] = useState(dayjs());
  const [visitTime, setVisitTime] = useState('');
  const [reason, setReason] = useState('');
  const [notes, setNotes] = useState('');
  const [tenantId, setTenantId] = useState('');
  const [status, setStatus] = useState('ouvert');
  const [duplicateBilling, setDuplicateBilling] = useState(true); // Default to Yes
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredPatients, setFilteredPatients] = useState(patients);
  const [consultation_Price, setConsultation_Price] = useState('');
  const [client_Part, setClient_Part] = useState('');
  const [showConsultationDetails, setShowConsultationDetails] = useState(false); // Zustand für die Checkbox
  const [insurance_Part, setInsurance_Part] = useState('');
  const [calendarOpen, setCalendarOpen] = useState(false); // Kalender wird standardmäßig nicht angezeigt
  const [appointments, setAppointments] = useState([]); // State to store appointments
  const [partnerId, setPartnerId] = useState('');
  const [lastVisitDate, setLastVisitDate] = useState(null);
  const [daysSinceLastVisit, setDaysSinceLastVisit] = useState(null);
  const [country, setCountry] = useState(''); 
  const [loading, setLoading] = useState(true);

 // console.log("ppppppppppp",partners);
  //console.log("ggggggggggggg",gewaehlerpatient);
  //console.log("vvvvvvvvvvvvv",visits);

  useEffect(() => {
    // Setze den Arzt als Standard, wenn nur ein Arzt in der Liste existiert
    if (doctors.length === 1) {
      setDoctorId(doctors[0].id);
    }
  }, [doctors]);

  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        const response = await axios.get('/api/visits');
    console.log("API Response:", response); // Logge die vollständige Antwort
    
    if (Array.isArray(response.data)) {
      const formattedAppointments = response.data.map(appointment => ({
        status: appointment.status,
        visitDate: appointment.visitDate, // Stelle sicher, dass die Daten das richtige Format haben
      }));
      setAppointments(formattedAppointments);
    } else {
      console.error("API response.data is not an array:", response.data);
    }
  } catch (error) {
    console.error("Error fetching appointments:", error);
  }
};

    if (open) {
      fetchAppointments(); // Termine laden, wenn der Dialog geöffnet wird
    }
  }, [open]);

  // Funktion zum Schließen des Kalenders
  const handleCalendarClose = () => {
    setCalendarOpen(false); // Kalender schließen
  };
  const handleCalendarOpen = () => {
    setCalendarOpen(true); // Kalender öffnen
  };

  const handleSlotSelection = (slotInfo) => {
    const start = dayjs(slotInfo.start);
    const end = dayjs(slotInfo.end);

    if (start.isValid() && end.isValid()) {
      setVisitDate(start); // Datum für die ausgewählte Zeit festlegen
      setVisitTime(start.format('HH:mm')); // Zeit im Format HH:mm festlegen
    } else {
      console.error("Ungültiges Datum oder Uhrzeit ausgewählt.");
    }

    handleCalendarClose(); // Kalender nach Auswahl schließen
  };


  useEffect(() => {
    if (tenants.length > 0) {
      const tenantcountry = tenants.find((tenant) => tenant.id === tenants[0].id);
      if (tenantcountry) {
        setTenantId(tenants[0].id);
        setCountry(tenantcountry.country);
       
        setLoading(false); // Set loading to false once country is set
      }
    }
  }, [tenants]);

  console.log("ccccccccc111111",country);
 // console.log("visitssssssssssss",visitsvomPatienten);

  useEffect(() => {
    // Filter patients based on search term
    setFilteredPatients(
      patients.filter(patient =>
        patient.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        patient.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        patient.phoneNumber.includes(searchTerm)
       
      )
      
    );

   
  }, [searchTerm, patients,tenants]);


  useEffect(() => {
    if (country && gewaehlerpatient && partners) {
      const partner = partners.find((partner) => partner.id === gewaehlerpatient.partnerId);
      if (partner) {
        setConsultation_Price(partner.Consultation_Price);
        setClient_Part(partner.Client_Part);
        setInsurance_Part(partner.Insurance_Part);
      }

      const visitsvomPatienten = visits
        .filter((visit) => visit.patientId === gewaehlerpatient.id)
        .sort((a, b) => new Date(b.visitDate) - new Date(a.visitDate));

      const letzterBesuch = visitsvomPatienten[0];
      if (letzterBesuch) {
        const lastVisitDate = dayjs(letzterBesuch.visitDate);
        setLastVisitDate(lastVisitDate);
        setDaysSinceLastVisit(dayjs().diff(lastVisitDate, 'day'));
      }
    }
  }, [gewaehlerpatient, country]);




      
const handleSubmit = async (e) => {
  e.preventDefault();
  try {
   const visitDateTime = dayjs(visitDate).set('hour', dayjs(visitTime, 'HH:mm').hour()).set('minute', dayjs(visitTime, 'HH:mm').minute());

    const finalConsultationPrice = showConsultationDetails ? consultation_Price : 0; // Setzt den Preis basierend auf Checkbox

    const newAppointment = {
      patientId: gewaehlerpatient.id,
      doctorId,
      visitDate: visitDateTime.toISOString(),
      reason,
      notes,
      tenantId,
      status,
      checkIn: 'Pas encore arrivé',
      duplicateBilling: duplicateBilling ? 'yes' : 'no',
   //   consultation_Price: finalConsultationPrice, // Fügt den Preis zur neuen Konsultation hinzu
    //  client_Part:client_Part,
     // insurance_Part:insurance_Part
    };


    onAddAppointment(newAppointment);
    
  } catch (error) {
    console.error('Failed to add appointment', error);
  }
};

if (loading) return <div>Loading...</div>; // Optional loading indicator

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Nouvelle consultation</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>


          <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="dense">
                <InputLabel>Médecin</InputLabel>
                <Select
                  value={doctorId}
                  onChange={(e) => setDoctorId(e.target.value)}
                  required
                >
                  {doctors.map((doctor) => (
                    <MenuItem key={doctor.id} value={doctor.id}>
                      {doctor.firstName} {doctor.lastName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>


    
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                label="Motif"
                fullWidth
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </Grid>


            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Tooltip title="Choisissez la date de visite">
                  <DatePicker
                    label="Date de visite"
                    value={visitDate}
                    onChange={(newValue) => setVisitDate(newValue)}
                    renderInput={(params) => <TextField {...params} fullWidth variant="outlined" />}
                    onOpen={handleCalendarOpen}
                  />
                </Tooltip>
              </LocalizationProvider>
            </Grid>


             <Grid item xs={12} md={6}>
              <Tooltip title="Sélectionnez l'heure de visite">
                <TextField
                  label="Heure de visite"
                  type="time"
                  fullWidth
                  variant="outlined"
                  value={visitTime}
                  onChange={(e) => setVisitTime(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="dense"
                />
              </Tooltip>
            </Grid>


          

          </Grid>
          <DialogActions>
            <Button onClick={onClose}>Annuler</Button>
            <Button type="submit" color="primary">
              Ajouter
            </Button>
          </DialogActions>
        </form>
      </DialogContent>

               {/* Kalender als Modal anzeigen */}
               <Dialog open={calendarOpen} onClose={handleCalendarClose} fullWidth maxWidth="lg">
      <DialogTitle>Verfügbare Termine</DialogTitle>
        <DialogContent>
          <AppointmentCalendar appointments={appointments} onSelectSlot={handleSlotSelection} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCalendarClose} color="primary">
            Fermer
          </Button>
        </DialogActions>
      </Dialog>

    </Dialog>
    
  );
};
 

const AddAppointmentDialog = ({ 
  open, 
  onClose, 
  onAddAppointment, 
  patients, 
  gewaehlerpatient,
  doctors, 
  tenants,
  partners,
  visits }) => {
  const [patientId, setPatientId] = useState('');
  const [doctorId, setDoctorId] = useState('');
  const [visitDate, setVisitDate] = useState(dayjs());
  const [visitTime, setVisitTime] = useState('');
  const [reason, setReason] = useState('');
  const [notes, setNotes] = useState('');
  const [tenantId, setTenantId] = useState('');
  const [status, setStatus] = useState('ouvert');
  const [duplicateBilling, setDuplicateBilling] = useState(true); // Default to Yes
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredPatients, setFilteredPatients] = useState(patients);
  const [consultation_Price, setConsultation_Price] = useState('');
  const [client_Part, setClient_Part] = useState('');
  const [showConsultationDetails, setShowConsultationDetails] = useState(false); // Zustand für die Checkbox

  const [insurance_Part, setInsurance_Part] = useState('');
  
  const [partnerId, setPartnerId] = useState('');
  const [lastVisitDate, setLastVisitDate] = useState(null);
  const [daysSinceLastVisit, setDaysSinceLastVisit] = useState(null);
  const [country, setCountry] = useState(''); 
  const [loading, setLoading] = useState(true);

 // console.log("ppppppppppp",partners);
  //console.log("ggggggggggggg",gewaehlerpatient);
  //console.log("vvvvvvvvvvvvv",visits);


  useEffect(() => {
    if (tenants.length > 0) {
      const tenantcountry = tenants.find((tenant) => tenant.id === tenants[0].id);
      if (tenantcountry) {
        setTenantId(tenants[0].id);
        setCountry(tenantcountry.country);
       
        setLoading(false); // Set loading to false once country is set
      }
    }
  }, [tenants]);

  console.log("ccccccccc222",country);
 // console.log("visitssssssssssss",visitsvomPatienten);

  useEffect(() => {
    // Filter patients based on search term
    setFilteredPatients(
      patients.filter(patient =>
        patient.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        patient.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        patient.phoneNumber.includes(searchTerm)
       
      )
      
    );

   
  }, [searchTerm, patients,tenants]);


  useEffect(() => {
    if (country && gewaehlerpatient && partners) {
      const partner = partners.find((partner) => partner.id === gewaehlerpatient.partnerId);
      if (partner) {
        setConsultation_Price(partner.Consultation_Price);
        setClient_Part(partner.Client_Part);
        setInsurance_Part(partner.Insurance_Part);
      }

      const visitsvomPatienten = visits
        .filter((visit) => visit.patientId === gewaehlerpatient.id)
        .sort((a, b) => new Date(b.visitDate) - new Date(a.visitDate));

      const letzterBesuch = visitsvomPatienten[0];
      if (letzterBesuch) {
        const lastVisitDate = dayjs(letzterBesuch.visitDate);
        setLastVisitDate(lastVisitDate);
        setDaysSinceLastVisit(dayjs().diff(lastVisitDate, 'day'));
      }
    }
  }, [gewaehlerpatient, country]);




      
const handleSubmit = async (e) => {
  e.preventDefault();
  try {
    const visitDateTime = dayjs(visitDate).set('hour', 0).set('minute', 0);

    const finalConsultationPrice = showConsultationDetails ? consultation_Price : 0; // Setzt den Preis basierend auf Checkbox

    const newAppointment = {
      patientId: gewaehlerpatient.id,
      doctorId,
      visitDate: visitDateTime.toISOString(),
      reason,
      notes,
      tenantId,
      status,
      checkIn: 'Arrivé',
      duplicateBilling: duplicateBilling ? 'yes' : 'no',
      consultation_Price: finalConsultationPrice, // Fügt den Preis zur neuen Konsultation hinzu
      client_Part:client_Part,
      insurance_Part:insurance_Part
    };


    onAddAppointment(newAppointment);
    
  } catch (error) {
    console.error('Failed to add appointment', error);
  }
};

if (loading) return <div>Loading...</div>; // Optional loading indicator

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Nouvelle consultation</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>


          <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="dense">
                <InputLabel>Médecin</InputLabel>
                <Select
                  value={doctorId}
                  onChange={(e) => setDoctorId(e.target.value)}
                  required
                >
                  {doctors.map((doctor) => (
                    <MenuItem key={doctor.id} value={doctor.id}>
                      {doctor.firstName} {doctor.lastName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>


    
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                label="Motif"
                fullWidth
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </Grid>


            {country === "Mauritanie" && (
                  <>
          <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                label="Date du dernier rendez-vous"
                fullWidth
                value={
                  lastVisitDate
                    ? `${lastVisitDate.format('DD/MM/YYYY')} (${
                        daysSinceLastVisit === 0 ? 'aujourd\'hui' : `${daysSinceLastVisit} jours`
                      })`
                    : 'Aucun rendez-vous précédent'
                }
                InputProps={{
                  readOnly: true,
                
                }}
              />
            </Grid>


          
           
          
                       
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showConsultationDetails}
                      onChange={(e) => setShowConsultationDetails(e.target.checked)}
                      color="primary"
                    />
                  }
                  label="Consultation?"
                />
              </Grid>

              {showConsultationDetails && (
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth margin="dense">
                    <InputLabel>Consultation</InputLabel>
                    <Select
                      value={consultation_Price ? consultation_Price : ''}
                      onChange={(e) => setConsultation_Price(e.target.value)}
                      required
                    >
                      <MenuItem value="" disabled>
                        Sélectionnez une consultation
                      </MenuItem>
                      <MenuItem value={consultation_Price}>
                        Total: {consultation_Price} MRU | Part Client {client_Part}%: {(consultation_Price * (client_Part / 100)).toFixed(2)} MRU | Part Assurance {insurance_Part}%: {(consultation_Price * (insurance_Part / 100)).toFixed(2)} MRU
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )}
               </>
                )}

          </Grid>
          <DialogActions>
            <Button onClick={onClose}>Annuler</Button>
            <Button type="submit" color="primary">
              Ajouter
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>

  );
};
 
const EditAppointmentDialog = ({
  open,
  onClose,
  appointment,
  onEditAppointment,
  patients,
  doctors,
  tenants,
  partners
}) => {
  // États généraux
  const [patientId, setPatientId] = useState('');
  const [doctorId, setDoctorId] = useState('');
  const [visitDate, setVisitDate] = useState(dayjs());
  const [visitTime, setVisitTime] = useState('');
  const [reason, setReason] = useState('');
  const [notes, setNotes] = useState('');
  const [tenantId, setTenantId] = useState('');
  const [status, setStatus] = useState('');
  const [duplicateBilling, setDuplicateBilling] = useState(true); // Default to Yes
  const [partnerId, setPartnerId] = useState('');

  // Diagnostiques, traitements et facture
  const [diagnoses, setDiagnoses] = useState([]);
  const [treatments, setTreatments] = useState([]);
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [invoiceDescription, setInvoiceDescription] = useState('');
  const [invoiceAmount, setInvoiceAmount] = useState('');
  const [consultationItems, setConsultationItems] = useState([]);
  const [invoiceConsultation,   setInvoiceConsultation] = useState(null);

  // Snackbar pour les notifications
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  // AI Dialog States
  const [openAiDialog, setOpenAiDialog] = useState(false);
  const [symptoms, setSymptoms] = useState('');
  const [aiResponse, setAiResponse] = useState('');
  const [loading, setLoading] = useState(false);

  // 2. Ajouter les états pour le modal de document
  const [documentOpen, setDocumentOpen] = useState(false);
  const [documentUrl, setDocumentUrl] = useState('');

  const [tabIndex, setTabIndex] = useState(0);



  
  const prescriptionRef = useRef();
const diagnosisPrintRef = useRef();
const invoicePrintRef = useRef();
const consultationInvoicePrintRef = useRef(); // Füge dies hinzu

   
  const patient = patients.find((pat) => pat.id === appointment.patientId);
  const partner = partners.find((partner) => partner.id === patient.partnerId);


  const doctor = doctors.find((doc) => doc.id === appointment.doctorId);
  const tenant = tenants.find((ten) => ten.id === appointment.tenantId);

  useEffect(() => {
    if (open && appointment) {
      setPatientId((appointment.patientId) || '');
      // Suche nach dem passenden Partner und setze den PartnerId

      setDoctorId(appointment.doctorId || '');
      setVisitDate(dayjs(appointment.visitDate) || dayjs());
      setVisitTime(dayjs(appointment.visitDate).format('HH:mm') || '');
      setReason(appointment.reason || '');
      setNotes(appointment.notes || '');
      setTenantId(appointment.tenantId || '');
      setStatus(appointment.status || 'ouvert');
      setDuplicateBilling(appointment.duplicateBilling === 'yes');
  

      const fetchData = async () => {
        try {
          const [diagnosesResult, treatmentsResult, invoicesResult] = await Promise.allSettled([
            axios.get(`api/diagnoses?visitId=${appointment.id}`),
            axios.get(`api/treatments?visitId=${appointment.id}`),
            axios.get(`api/invoices?visitId=${appointment.id}`)
          ]);
  
          // Diagnosen verarbeiten
          if (diagnosesResult.status === 'fulfilled') {
            const fetchedDiagnoses = diagnosesResult.value.data.map(diagnosis => ({
              ...diagnosis,
              filePath: diagnosis.filePath || null,
              file: null
            }));
            setDiagnoses(fetchedDiagnoses);
          } else {
            console.error('Fehler beim Abrufen der Diagnosen:', diagnosesResult.reason);
            setSnackbarMessage('Fehler beim Laden der Diagnosen.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
          }
  
          // Behandlungen verarbeiten
          if (treatmentsResult.status === 'fulfilled') {
            setTreatments(treatmentsResult.value.data || []);
          } else {
            console.error('Fehler beim Abrufen der Behandlungen:', treatmentsResult.reason);
            setSnackbarMessage('Fehler beim Laden der Behandlungen.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
          }
  
          // Rechnungen verarbeiten
          if (invoicesResult.status === 'fulfilled') {
            setInvoiceItems(invoicesResult.value.data.items || []);
          } else {
            console.error('Fehler beim Abrufen der Rechnungen:', invoicesResult.reason);
            // Optional: Setzen Sie invoiceItems auf ein leeres Array, falls keine Rechnungen vorhanden sind
            setInvoiceItems([]);
            // Sie können entscheiden, ob Sie den Benutzer darüber informieren möchten
            // setSnackbarMessage('Keine Rechnungen gefunden oder Fehler beim Laden der Rechnungen.');
            // setSnackbarSeverity('warning');
            // setSnackbarOpen(true);
          }
        } catch (error) {
          console.error('Allgemeiner Fehler beim Abrufen der Daten:', error);
          setSnackbarMessage('Allgemeiner Fehler beim Laden der Daten.');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        }

        try {
          const consultationInvoiceResponse = await axios.get(`api/invoiceconsultation?visitId=${appointment.id}`);
          console.log("Consultation Invoice Response:", consultationInvoiceResponse.data);
      
          if (consultationInvoiceResponse.data && consultationInvoiceResponse.data.invoice && consultationInvoiceResponse.data.items) {
            setInvoiceConsultation(consultationInvoiceResponse.data.invoice);
            setConsultationItems(consultationInvoiceResponse.data.items);
            console.log("Consultation Invoice:", consultationInvoiceResponse.data.invoice);
            console.log("Consultation Items:", consultationInvoiceResponse.data.items);
          } else {
            console.log("No consultation invoice or items found");
          }
        } catch (error) {
          console.error('Failed to fetch consultation invoice:', error);
        }
      };
  
      fetchData();
    }
  }, [open, appointment]);

  console.log("äääääääääääääää",patients.partnerId)
  console.log("äääääääääääääää",partners)



  const handlePrintPrescription = useReactToPrint({
    content: () => prescriptionRef.current,
  });

  const handlePrintInvoice = useReactToPrint({
    content: () => invoicePrintRef.current,
  });

  const handlePrintConsultationInvoice = useReactToPrint({
    content: () => consultationInvoicePrintRef.current,
  });

// 2. Druckfunktion für Diagnosen definieren
const handlePrintDiagnosis = useReactToPrint({
  content: () => diagnosisPrintRef.current,
});
  
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  // Fonction pour ouvrir le modal de document
const handleOpenDocument = (filePath) => {
  setDocumentUrl(filePath);
  setDocumentOpen(true);
};

// Fonction pour fermer le modal de document
const handleCloseDocument = () => {
  setDocumentOpen(false);
  setDocumentUrl('');
};

// Fonction pour ouvrir le modal AI
const handleOpenAiDialog = () => {
  setOpenAiDialog(true);
};

  // Fonction pour changer les champs de diagnostique
  const handleDiagnosisChange = (index, field, value) => {
    const newDiagnoses = [...diagnoses];
    newDiagnoses[index][field] = value;
    setDiagnoses(newDiagnoses);
  };

  // Fonction pour supprimer un diagnostique
  const removeDiagnosis = async (index, id) => {
    if (id) {
      try {
        await axios.delete(`api/diagnoses/${id}`);
        const newDiagnoses = diagnoses.filter((_, i) => i !== index);
        setDiagnoses(newDiagnoses);
        setSnackbarMessage('Diagnostique supprimé avec succès.');
        setSnackbarSeverity('success');
      } catch (error) {
        console.error('Échec de la suppression du diagnostique:', error);
        setSnackbarMessage('Erreur lors de la suppression du diagnostique.');
        setSnackbarSeverity('error');
      } finally {
        setSnackbarOpen(true);
      }
    } else {
      const newDiagnoses = diagnoses.filter((_, i) => i !== index);
      setDiagnoses(newDiagnoses);
    }
  };

  // Fonction pour ajouter un nouveau diagnostique
  const addDiagnosis = () => {
    setDiagnoses([
      ...diagnoses,
      {
        diagnosis: '',
        diagnosisDate: dayjs().format('YYYY-MM-DD'),
        tenantId,
        filePath: null,
        file: null
      }
    ]);
  };

  // Fonction pour changer un traitement
  const handleTreatmentChange = (index, value) => {
    const newTreatments = [...treatments];
    newTreatments[index].treatment = value;
    setTreatments(newTreatments);
  };

  // Fonction pour supprimer un traitement
  const removeTreatment = async (index, id) => {
    if (id) {
      try {
        await axios.delete(`api/treatments/${id}`);
        const newTreatments = treatments.filter((_, i) => i !== index);
        setTreatments(newTreatments);
        setSnackbarMessage('Traitement supprimé avec succès.');
        setSnackbarSeverity('success');
      } catch (error) {
        console.error('Échec de la suppression du traitement:', error);
        setSnackbarMessage('Erreur lors de la suppression du traitement.');
        setSnackbarSeverity('error');
      } finally {
        setSnackbarOpen(true);
      }
    } else {
      const newTreatments = treatments.filter((_, i) => i !== index);
      setTreatments(newTreatments);
    }
  };

  // Fonction pour ajouter un nouveau traitement
  const addTreatment = () => {
    setTreatments([
      ...treatments,
      {
        treatment: '',
        treatmentDate: dayjs().format('YYYY-MM-DD'),
        tenantId,
        patientId
      }
    ]);
  };

  // Fonction pour ajouter un nouvel item de facture
  const handleAddInvoiceItem = () => {
    if (invoiceDescription && invoiceAmount) {
      const partner = partners?.find(partner => partner.id === patients?.find(pat => pat.id === patientId)?.partnerId);
      const partClient = partner ? partner.Client_Part : 0;
      const partAssurance = partner ? partner.Insurance_Part : 0;

      setInvoiceItems([
        ...invoiceItems,
        {
          description: invoiceDescription,
          amount: parseFloat(invoiceAmount),
          part_client: partClient,
          part_assurance: partAssurance
        }
      ]);

      setInvoiceDescription('');
      setInvoiceAmount('');
    }
  };

  // Fonction pour supprimer un item de facture
  const handleRemoveInvoiceItem = async (index, id) => {
    if (id) {
      try {
        const response = await axios.delete(`api/invoiceItems/${id}`);
        setSnackbarMessage(response.data.message || 'Item de facture supprimé avec succès.');
        setSnackbarSeverity('success');
        const newItems = invoiceItems.filter((_, i) => i !== index);
        setInvoiceItems(newItems);
      } catch (error) {
        console.error('Échec de la suppression de l\'item de facture:', error);
        setSnackbarMessage('Erreur lors de la suppression de l\'item de facture.');
        setSnackbarSeverity('error');
      } finally {
        setSnackbarOpen(true);
      }
    } else {
      const newItems = invoiceItems.filter((_, i) => i !== index);
      setInvoiceItems(newItems);
    }
  };

  // Fonction pour changer le fichier d'un diagnostique
  const handleFileChange = (index, e) => {
    const newDiagnoses = [...diagnoses];
    newDiagnoses[index].file = e.target.files[0]; // Fichier stocké dans le diagnostique
    setDiagnoses(newDiagnoses);
  };

  // Fonction pour uploader le fichier d'un diagnostique
  const uploadFile = async (index) => {
    const selectedFile = diagnoses[index].file;
    if (!selectedFile) return diagnoses[index].filePath; // Pas de nouveau fichier, conserver le filePath existant

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const response = await axios.post('api/diagnoses/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log(`Fichier pour Diagnostique ${index + 1} uploadé avec succès. Chemin du fichier:`, response.data.filePath);
      return response.data.filePath; // Retourner le chemin du fichier après upload
    } catch (error) {
      console.error(`Échec de l'upload du fichier pour Diagnostique ${index + 1}:`, error.response ? error.response.data : error.message);
      setSnackbarMessage(`Erreur lors de l'upload du fichier pour Diagnostique ${index + 1}.`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return diagnoses[index].filePath; // Conserver le filePath existant en cas d'erreur
    }
  };

  // Fonction pour supprimer le fichier d'un diagnostique
  const deleteUploadedFile = async (index) => {
    const diagnosisId = diagnoses[index].id;
    if (!diagnosisId) {
      setSnackbarMessage('Aucun ID de diagnostique pour supprimer le fichier.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    try {
      await axios.delete(`api/diagnoses/${diagnosisId}/document`);
      const newDiagnoses = [...diagnoses];
      newDiagnoses[index].filePath = null; // Supprimer le fichier
      setDiagnoses(newDiagnoses);
      setSnackbarMessage('Fichier supprimé avec succès.');
      setSnackbarSeverity('success');
    } catch (error) {
      console.error(`Échec de la suppression du fichier pour Diagnostique ${index + 1}:`, error);
      setSnackbarMessage(`Erreur lors de la suppression du fichier pour Diagnostique ${index + 1}.`);
      setSnackbarSeverity('error');
    } finally {
      setSnackbarOpen(true);
    }
  };

  // Fonction pour envoyer les symptômes à l'IA et obtenir une réponse
  const handleSendToAi = async () => {
    setLoading(true); // Afficher le loader
    try {
      const response = await axios.post('api/Analyze', {
        symptoms: `${symptoms} (Veuillez répondre en français. Je souhaite une réponse en 2 parties : 1. Une cause possible des symptômes et 2. Une suggestion de traitement. Je souhaite une réponse courte afin que le médecin n'ait pas à lire longtemps.)`
      });
      setAiResponse(response.data.analysis); // Définir la réponse de l'IA
    } catch (error) {
      console.error('Échec de l\'analyse des symptômes:', error);
      setSnackbarMessage('Erreur lors de l\'analyse des symptômes.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false); // Cacher le loader
    }
  };

  // Fonction pour soumettre le formulaire
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Calculer la date et l'heure exactes de la visite
      
      const visitDateTime = dayjs(visitDate)
        .set('hour', dayjs(visitTime, 'HH:mm').hour())
        .set('minute', dayjs(visitTime, 'HH:mm').minute());

      // Mettre à jour le rendez-vous
      const updatedAppointment = {
        ...appointment,
        patientId,
        doctorId,
        visitDate: visitDateTime.toISOString(),
        reason,
        notes,
        tenantId,
        status,
        duplicateBilling: duplicateBilling ? 'yes' : 'no',
      };
      await onEditAppointment(updatedAppointment);

      // Mettre à jour les diagnostiques et les traitements
      await Promise.all([
        ...diagnoses.map(async (diagnosis, index) => {
          const filePath = await uploadFile(index); // Upload du fichier et obtention du filePath
          if (diagnosis.id) {
            // Mettre à jour le diagnostique existant
            await axios.put(`api/diagnoses/${diagnosis.id}`, { ...diagnosis, filePath });
          } else {
            // Créer un nouveau diagnostique
            await axios.post('api/diagnoses', { ...diagnosis, visitId: appointment.id, patientId, filePath });
          }
        }),
        ...treatments.map(async (treatment) => {
          if (treatment.id) {
            // Mettre à jour le traitement existant
            await axios.put(`api/treatments/${treatment.id}`, treatment);
          } else {
            // Créer un nouveau traitement
            await axios.post('api/treatments', { ...treatment, visitId: appointment.id, patientId });
          }
        }),
      ]);

      // Mettre à jour les items de facture
      if (invoiceItems.length > 0) {
        await axios.post('api/invoices', {
          patientId,
          visitId: appointment.id,
          tenantId,
          items: invoiceItems,
          Type: "Facture",
        });
      }

      setSnackbarMessage('Rendez-vous mis à jour avec succès.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      onClose();
    } catch (error) {
      console.error('Échec de la mise à jour du rendez-vous:', error);
      setSnackbarMessage('Erreur lors de la mise à jour du rendez-vous.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  // Fonction pour fermer le dialog
  const handleDialogClose = () => {
    setDiagnoses([]);
    setTreatments([]);
    setInvoiceItems([]);
    onClose();
    // 6. Fermer le modal de document si ouvert
    setDocumentOpen(false);
    setDocumentUrl('');
  };

  // Fonction pour fermer le Snackbar
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };



  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Modifier le rendez-vous</DialogTitle>
      <DialogContent>
      <form onSubmit={handleSubmit}>
        <Tabs value={tabIndex} onChange={handleTabChange}>
          <Tab label="Diagnostiques" />
          <Tab label="Traitements" />
          <Tab label="Factures" />
          {tenant.country === "Mauritanie" && <Tab label="Consultation" />}

        </Tabs>

        {/* Diagnose Tab */}
        {tabIndex === 0 && (
          <Box p={3}>
            {/* Diagnose-Druckvorlage anzeigen */}
                <DiagnosisPrint
                ref={diagnosisPrintRef}
                  diagnoses={diagnoses}
                  patient={patients.find((p) => p.id === patientId)}
                  doctor={doctors.find((d) => d.id === doctorId)}
                  tenant={tenants.find((t) => t.id === tenantId)}
                  partner={partner}
                />
            <Grid container spacing={0}>

                <Typography variant="h6">
                  Diagnostiques
                </Typography>
                <Button onClick={handlePrintDiagnosis} color="primary" startIcon={<PrintIcon />}>
           
                    </Button>

              {diagnoses.map((diagnosis, index) => (
                <Grid
                  container
                  spacing={2}
                  key={index}
                  style={{
                    padding: '10px',
                    marginBottom: '10px',
                    borderRadius: '5px',
                    border: '1px solid #ccc'
                  }}
                >
                  <Grid item xs={12} md={6}>
                    <TextField
                      margin="dense"
                      label={`Diagnostique ${index + 1}`}
                      fullWidth
                      value={diagnosis.diagnosis}
                      onChange={(e) =>
                        handleDiagnosisChange(index, 'diagnosis', e.target.value)
                      }
                      multiline
                      rows={2}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Button variant="outlined" component="label">
                      Choisir un fichier
                      <input
                        type="file"
                        hidden
                        onChange={(e) => {
                          const newDiagnoses = [...diagnoses];
                          newDiagnoses[index].file = e.target.files[0];
                          setDiagnoses(newDiagnoses);
                        }}
                      />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Button
                      onClick={() => removeDiagnosis(index, diagnosis.id)}
                      color="secondary"
                    >
                      Supprimer le diagnostique
                    </Button>
                  </Grid>
                </Grid>
              ))}
              <Grid item xs={12}>
                <Button onClick={addDiagnosis} color="primary" variant="contained">
                  Ajouter un diagnostique
                </Button>
                




              </Grid>
            </Grid>
          </Box>
        )}

        {/* Treatment Tab */}
        {tabIndex === 1 && (
          <Box p={3}>
            <Prescription
                ref={prescriptionRef}
                patient={patients.find((p) => p.id === patientId)}
                doctor={doctors.find((d) => d.id === doctorId)}
                tenant={tenants.find((t) => t.id === tenantId)}
                treatments={treatments}
                partner={partner}
              />

            <Grid container spacing={0}>

              <Typography variant="h6">Traitements</Typography>
              <Button onClick={handlePrintPrescription} color="primary" startIcon={<PrintIcon />}>
                     
                    </Button>
              {treatments.map((treatment, index) => (
                <Grid
                  container
                  spacing={2}
                  key={index}
                  style={{
                    padding: '10px',
                    marginBottom: '10px',
                    borderRadius: '5px',
                    border: '1px solid #ccc'
                  }}
                >
                  <Grid item xs={12}>
                    <TextField
                      margin="dense"
                      label={`Traitement ${index + 1}`}
                      fullWidth
                      value={treatment.treatment}
                      onChange={(e) =>
                        handleTreatmentChange(index, e.target.value)
                      }
                      multiline
                      rows={2}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      onClick={() => removeTreatment(index, treatment.id)}
                      color="secondary"
                    >
                      Supprimer le traitement
                    </Button>
                  </Grid>
                </Grid>
              ))}
              <Grid item xs={12}>
                <Button onClick={addTreatment} color="primary" variant="contained">
                  Ajouter un traitement
                </Button>
                
              </Grid>
            </Grid>
          </Box>
        )}



{tabIndex === 2 && (
          <Box p={3}>


            <InvoicePrint
                ref={invoicePrintRef}
                patient={patients.find((p) => p.id === patientId)}
                doctor={doctors.find((d) => d.id === doctorId)}
                tenant={tenants.find((t) => t.id === tenantId)}
                invoiceItems={invoiceItems}
                partner={partner}
            
              />


    <Grid container spacing={0}>
            <Typography variant="h6">Facture</Typography>
            <Button onClick={handlePrintInvoice} color="primary" startIcon={<PrintIcon />}>
            </Button>
            </Grid>

            {invoiceItems.map((item, index) => (
              <Grid container spacing={2} key={index} alignItems="center" style={{ marginBottom: '10px' }}>
                <Grid item xs={8}>
                  <TextField
                    margin="dense"
                    label={`Description ${index + 1}`}
                    fullWidth
                    value={item.description}
                    onChange={(e) => {
                      const newInvoiceItems = [...invoiceItems];
                      newInvoiceItems[index].description = e.target.value;
                      setInvoiceItems(newInvoiceItems);
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    margin="dense"
                    label="Montant"
                    type="number"
                    fullWidth
                    value={item.amount}
                    onChange={(e) => {
                      const newInvoiceItems = [...invoiceItems];
                      newInvoiceItems[index].amount = parseFloat(e.target.value);
                      setInvoiceItems(newInvoiceItems);
                    }}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Button onClick={() => handleRemoveInvoiceItem(index, item.id)} color="secondary">
                    Supprimer
                  </Button>
                </Grid>
              </Grid>
            ))}
            <Grid container spacing={2} alignItems="center" style={{ marginTop: '10px' }}>
              <Grid item xs={8}>
                <TextField
                  margin="dense"
                  label="Nouvelle description"
                  fullWidth
                  value={invoiceDescription}
                  onChange={(e) => setInvoiceDescription(e.target.value)}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  margin="dense"
                  label="Nouveau montant"
                  type="number"
                  fullWidth
                  value={invoiceAmount}
                  onChange={(e) => setInvoiceAmount(e.target.value)}
                />
              </Grid>
              <Grid item xs={1}>
                <Button onClick={handleAddInvoiceItem} color="primary" variant="contained">
                  Ajouter
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}

         

                 {/* Treatment Tab */}
            {tabIndex === 3 && tenant.country === "Mauritanie" && (
            <Box p={3}>
              <Grid item xs={12}>
                <Typography variant="h6"></Typography>
                <ConsultationInvoicePrint
                  ref={consultationInvoicePrintRef}
                  patient={patient}
                  doctor={doctor}
                  tenant={tenant}
                  consultationItems={consultationItems}
                  partner={partner}
                  appointment={appointment}
                />
              </Grid>

              <Grid container spacing={0}>
                <Typography variant="h6">Imprimer La Consultation</Typography>
                <Button onClick={handlePrintConsultationInvoice} color="primary" startIcon={<PrintIcon />}>
                  Imprimer
                </Button>
              </Grid>
            </Box>
          )}


            
            <DialogActions>
        <Button onClick={onClose}>Annuler</Button>
        <Button type="submit" color="primary" variant="contained">
          Enregistrer
        </Button>
      </DialogActions>
      </form>
      </DialogContent>


      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert severity={snackbarSeverity}>{snackbarMessage}</Alert>
      </Snackbar>

      <Dialog open={documentOpen} onClose={() => setDocumentOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>Afficher le document</DialogTitle>
        <DialogContent dividers>
          {documentUrl && (
            <>
              {documentUrl.toLowerCase().endsWith('.pdf') ? (
                <iframe
                  src={documentUrl}
                  title="Document PDF"
                  width="100%"
                  height="600px"
                  style={{ border: 'none' }}
                />
              ) : documentUrl.match(/\.(jpeg|jpg|gif|png)$/) ? (
                <img src={documentUrl} alt="Document" style={{ width: '100%', height: 'auto' }} />
              ) : (
                <Typography variant="body1" color="textSecondary">
                  Ce type de fichier n'est pas pris en charge pour l'affichage dans le navigateur.
                </Typography>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDocumentOpen(false)} color="primary">
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};





const ViewPatientDialog = ({ open, onClose, patient, tabIndex, setTabIndex, partners }) => {
  const [doctors, setDoctors] = useState([]);
  const [visits, setVisits] = useState([]);
  const [diagnoses, setDiagnoses] = useState({});
  const [treatments, setTreatments] = useState({});
  const [activeButton, setActiveButton] = useState('all');
  const [filterDoctor, setFilterDoctor] = useState('');
  const [action, setAction] = useState('');
  const [selected, setSelected] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [orderBy, setOrderBy] = useState('visitDate');
  const [order, setOrder] = useState('asc');
  const [addAppointmentOpen, setAddAppointmentOpen] = useState(false);
  const [appointments, setAppointments] = useState([]); // State to store appointments
  // Références pour l'impression (si nécessaire)
  const prescriptionRef = useRef();
  const diagnosisPrintRef = useRef();
  const invoicePrintRef = useRef();
  const consultationInvoicePrintRef = useRef();
  const [viewAppointmentOpen, setViewAppointmentOpen] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [addSuccessOpen, setAddSuccessOpen] = useState(false);
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [patients, setPatients] = useState([]);
  const [tenants, setTenants] = useState([]);
  const [addErrorOpen, setAddErrorOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [deleteSuccessOpen, setDeleteSuccessOpen] = useState(false);
  const [editAppointmentOpen, setEditAppointmentOpen] = useState(false);
  const [editSuccessOpen, setEditSuccessOpen] = useState(false);
  const [editErrorOpen, setEditErrorOpen] = useState(false);
  const [editErrorMessage, setEditErrorMessage] = useState('');
  const [deleteErrorOpen, setDeleteErrorOpen] = useState(false);
  const [dateFilter, setDateFilter] = useState(dayjs().startOf('day')); // Standard auf aktuelles Datum
  const [documentOpen, setDocumentOpen] = useState(false); // State für Dokumenten-Modal
const [documentUrl, setDocumentUrl] = useState(''); // State zur Speicherung der Dokument-URL

console.log("Aliouneeeeeeeeeeeeeeeeeeeeeeeeee");
  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        const response = await axios.get(`api/doctors`);
        setDoctors(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des médecins:', error);
      }
    };

    const fetchPatients = async () => {
      const response = await axios.get('api/patients');
      setPatients(response.data);
    };
  
    const fetchTenants = async () => {
      const response = await axios.get('api/tenants');
      setTenants(response.data);
    };
  
    const fetchAppointments = async () => {
      const response = await axios.get('api/visits');
      setAppointments(response.data);
    };



    const fetchVisits = async () => {
      try {
        const response = await axios.get(`api/patients/${patient.id}/visits`);
        setVisits(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des visites:', error);
      }
    };

 

    if (open && patient && patient.id) {
      fetchDoctors();
      fetchVisits();
      fetchTenants();
      fetchAppointments();
      fetchPatients();
      
      console.log(patient);
      console.log(patient.id);

    }

  }, [open, patient]);

  const handleDateFilterChange = (newDate) => {
    setDateFilter(newDate);
  };
  
  useEffect(() => {
    const fetchDataForTab1 = async () => {
      if (tabIndex === 1 && selectedAppointment) {
        try {
          // Fetch Diagnoses and Treatments
          const diagnosesResponse = await axios.get(`api/diagnoses?visitId=${selectedAppointment.id}`);
          setDiagnoses(diagnosesResponse.data || []);
  
          const treatmentsResponse = await axios.get(`api/treatments?visitId=${selectedAppointment.id}`);
          setTreatments(treatmentsResponse.data || []);
        } catch (error) {
          console.error('Failed to fetch diagnoses or treatments:', error);
        }
  
        // Fetch invoice items separately
        try {
          const invoicesResponse = await axios.get(`api/invoices?visitId=${selectedAppointment.id}`);
          setInvoiceItems(invoicesResponse.data.items || []);
          setTotalAmount(invoicesResponse.data.totalAmount || 0);
        } catch (error) {
          console.error('Failed to fetch invoices:', error);
        }
      }
    };
  
    fetchDataForTab1();
  }, [tabIndex, selectedAppointment]);
  
  
  const handleViewAppointmentOpen = async (appointment) => {
    setSelectedAppointment({
      ...appointment,
      visitDate: dayjs(appointment.visitDate),
      created_at: dayjs(appointment.created_at),
    });
  
    // Fetch diagnoses and treatments independently
    try {
      const diagnosesResponse = await axios.get(`api/diagnoses?visitId=${appointment.id}`);
      setDiagnoses(diagnosesResponse.data || []);
  
      const treatmentsResponse = await axios.get(`api/treatments?visitId=${appointment.id}`);
      setTreatments(treatmentsResponse.data || []);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  
    // Fetch invoice items separately
    try {
      const invoicesResponse = await axios.get(`api/invoices?visitId=${appointment.id}`);
      setInvoiceItems(invoicesResponse.data.items || []);
      setTotalAmount(invoicesResponse.data.totalAmount || 0);
    } catch (error) {
      console.error('Failed to fetch invoices:', error);
    }
  
  
    setViewAppointmentOpen(true);
  };


    const handleEditAppointmentOpen = async (appointment) => {
      setSelectedAppointment({
        ...appointment,
        visitDate: dayjs(appointment.visitDate),
        created_at: dayjs(appointment.created_at),
      });
    
      // Fetch diagnoses and treatments independently
      try {
        const diagnosesResponse = await axios.get(`api/diagnoses?visitId=${appointment.id}`);
        setDiagnoses(diagnosesResponse.data || []);
    
        const treatmentsResponse = await axios.get(`api/treatments?visitId=${appointment.id}`);
        setTreatments(treatmentsResponse.data || []);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    
      // Fetch invoice items separately
      try {
        const invoicesResponse = await axios.get(`api/invoices?visitId=${appointment.id}`);
        setInvoiceItems(invoicesResponse.data.items || []);
        setTotalAmount(invoicesResponse.data.totalAmount || 0);
      } catch (error) {
        console.error('Failed to fetch invoices:', error);
      }
    
      setEditAppointmentOpen(true);
    };

    
  useEffect(() => {
    if (action === 'view' && selected) {
      const appointment = appointments.find(app => app.id === selected);
      handleViewAppointmentOpen(appointment);
    } else if (action === 'edit' && selected) {
      const appointment = appointments.find(app => app.id === selected);
      handleEditAppointmentOpen(appointment);
    } else if (action === 'delete') {
      setDeleteConfirmOpen(true);
    }
    setAction('');
  }, [action, selected, appointments]);

  
  



  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const diagnosesPromises = visits.map(visit =>
          axios.get(`api/visits/${visit.id}/diagnoses`)
        );
        const treatmentsPromises = visits.map(visit =>
          axios.get(`api/visits/${visit.id}/treatments`)
        );

        const diagnosesResponses = await Promise.all(diagnosesPromises);
        const treatmentsResponses = await Promise.all(treatmentsPromises);

        const diagnosesData = {};
        const treatmentsData = {};

        diagnosesResponses.forEach((response, index) => {
          diagnosesData[visits[index].id] = response.data;
        });

        treatmentsResponses.forEach((response, index) => {
          treatmentsData[visits[index].id] = response.data;
        });

        setDiagnoses(diagnosesData);
        setTreatments(treatmentsData);
      } catch (error) {
        console.error('Erreur lors de la récupération des détails des visites:', error);
      }
    };

    if (visits.length > 0) {
      fetchDetails();
    }
  }, [visits]);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };


  const handleActionChange = (event) => {
    setAction(event.target.value);
  };
  const handleAddAppointmentOpen = () => {
    setAddAppointmentOpen(true);
  };
  const handleFilterChange = (filter) => {
    setActiveButton(filter);
  };
 const handleSearchChange = (event) => {
    setSearchFilter(event.target.value);
  };
  const handleAppointmentClick = (id) => {
    setSelected(id);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

// Aktualisierte Filterlogik für die Termine
const filteredAppointments = appointments.filter((appointment) => {
  // Filtert nur die Termine für das aktuelle Datum
  return (
    appointment.patientId === patient.id &&

    // Nur Termine zeigen, wo der Status auf Arrive steht. Wichtig für Termine anlegen. 
    appointment.checkIn === 'Arrivé' &&
  //  appointment.status !== 'complété' &&
    dayjs(appointment.visitDate).isSame(dateFilter, 'day') // Prüft, ob das visitDate dem Filterdatum entspricht
  );
}).filter((appointment) => {
  const doctor = doctors.find(doc => doc.id === appointment.doctorId);
  return (
    appointment.reason?.toLowerCase().includes(searchFilter.toLowerCase()) ||
    (doctor?.lastName?.toLowerCase().includes(searchFilter.toLowerCase())) ||
    (filterDoctor ? doctor?.id === filterDoctor : true)
  );
});
  
  const sortedAppointments = filteredAppointments.sort((a, b) => {
    if (order === 'asc') {
      return a[orderBy] < b[orderBy] ? -1 : 1;
    } else {
      return a[orderBy] > b[orderBy] ? -1 : 1;
    }
  });

  const isSelected = (id) => selected === id;

  

  const partnerName = partners.find(partner => partner.id === patient.partnerId)?.Name || 'Inconnu';

  // Définir l'URL de base de votre serveur (ajustez selon votre configuration)
  const serverBaseUrl = '/uploads'; // Remplacez par votre URL de serveur réelle

  // Fonction pour ouvrir le document dans un nouvel onglet
  const handleOpenDocument = (filePath) => {
    setDocumentUrl(filePath);
    setDocumentOpen(true);
  };
  
  const handleCloseDocument = () => {
    setDocumentOpen(false);
    setDocumentUrl('');
  };
  

  const handleAddAppointmentClose = () => {
    setAddAppointmentOpen(false);
  };

  
  const handleAddAppointment = async (newAppointment) => {
    try {
      const response = await axios.post('api/visits', newAppointment);
      setAppointments((prevAppointments) => [...prevAppointments, response.data]);
      setAddAppointmentOpen(false);
      setAddSuccessOpen(true);
  
      // PayPeriod in der Konsole ausgeben
      if (response.data.payPeriod !== undefined) {
        console.log(`Die payPeriod für diesen Besuch beträgt: ${response.data.payPeriod} Tage.`);
      }
    } catch (error) {
      console.error('Failed to add appointment', error);
      setAddAppointmentOpen(false);
      setAddErrorOpen(true);
    }
  };
  
  

 const handleViewAppointmentClose = () => {
    setViewAppointmentOpen(false);
    setDiagnoses([]);
    setTreatments([]);
    setInvoiceItems([]);
    setTotalAmount(0);
  };

  const handlePrintDiagnosis = useReactToPrint({
    content: () => diagnosisPrintRef.current,
  });

  const handlePrintPrescription = useReactToPrint({
    content: () => prescriptionRef.current,
  });

  const handlePrintInvoice = useReactToPrint({
    content: () => invoicePrintRef.current,
  });

  const handleEditAppointmentClose = () => {
    setEditAppointmentOpen(false);
    setDiagnoses([]);
    setTreatments([]);
    setInvoiceItems([]);
    setTotalAmount(0);
  };

  const handleDeleteAppointment = async () => {
    try {
      await axios.delete(`api/visits/${selected}`);
      setAppointments((prevAppointments) =>
        prevAppointments.filter((appointment) => appointment.id !== selected)
      );
      setSelected('');
      setDeleteConfirmOpen(false);
      setDeleteSuccessOpen(true);
    } catch (error) {
      console.error('Failed to delete appointment', error);
      setDeleteConfirmOpen(false);
      setDeleteErrorOpen(true);
    }
  };

  const handleEditAppointment = async (updatedAppointment) => {
    if (updatedAppointment.status === 'complété') {
      // Wenn der Status auf "complété" steht, das Bearbeiten verhindern
      setEditErrorMessage('');
      setEditErrorOpen(true);
      return;
    }
  
    // Verhindern der Änderung von visitDate, wenn checkIn auf "Arrivé" gesetzt ist
    const originalAppointment = appointments.find(app => app.id === updatedAppointment.id);
    if (originalAppointment?.checkIn === 'Arrivé' && !dayjs(originalAppointment.visitDate).isSame(updatedAppointment.visitDate)) {
      setEditErrorMessage('');
      setEditErrorOpen(true);
      return;
    }
  
    try {
      const response = await axios.put(`api/visits/${updatedAppointment.id}`, updatedAppointment);
      setAppointments((prevAppointments) =>
        prevAppointments.map((appointment) =>
          appointment.id === updatedAppointment.id ? response.data : appointment
        )
      );
      setEditAppointmentOpen(false);
      setEditSuccessOpen(true);
    } catch (error) {
      console.error('Failed to update appointment', error);
  
      // Setzen der Fehlermeldung aus dem Backend
      setEditErrorMessage(error.response?.data?.error || 'Échec de la mise à jour du rendez-vous');
  
      setEditAppointmentOpen(false);
      setEditErrorOpen(true);
    }
  };

  const handleStatusChange = async (id, status) => {
    const appointment = appointments.find(app => app.id === id);

    
    if (status === 'complété' && appointment.checkIn !== 'Arrivé') {
      alert('Le patient doit d\'abord être marqué comme Arrivé.');
      return;
    }

    try {
      const response = await axios.put(`api/visits/${id}`, { status });
      setAppointments((prevAppointments) =>
        prevAppointments.map((appointment) =>
          appointment.id === id ? response.data : appointment
        )
      );
    } catch (error) {
      console.error('Failed to update status', error);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
        <DialogTitle>Voir les Consultation</DialogTitle>
        <DialogContent>
          <Tabs value={tabIndex} onChange={handleTabChange} aria-label="basic tabs example">

          
          
            <Tab label="Consultations précédentes. " />

            <Tab label="Consultation en cours" />
          </Tabs>
         
          <TabPanel value={tabIndex} index={0}>
  {visits.map((visit) => {
    if (visit.checkIn !== 'Arrivé') {
      return null; // Überspringt das Rendering, wenn checkIn nicht 'Arrivé' ist
    }

    const doctor = doctors.find(doc => doc.id === visit.doctorId) || {};
    const visitDiagnoses = diagnoses[visit.id] || [];
    const visitTreatments = treatments[visit.id] || [];

    return (
      <StyledTableContainer component={Paper} key={visit.id} sx={{ marginBottom: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Date</StyledTableCell>
              <StyledTableCell>Docteur</StyledTableCell>
              <StyledTableCell>Raison</StyledTableCell>
              <StyledTableCell>Remarques</StyledTableCell>
              <StyledTableCell>Statut</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow>
              <TableCell>{dayjs(visit.visitDate).format('DD/MM/YYYY')}</TableCell>
              <TableCell>{doctor.firstName} {doctor.lastName}</TableCell>
              <TableCell>{visit.reason}</TableCell>
              <TableCell>{visit.notes}</TableCell>
              <TableCell>{visit.status}</TableCell>
            </StyledTableRow>
            <StyledTableRow>
              <TableCell colSpan={5}>
                <Typography variant="subtitle1">Diagnostics :</Typography>
                {visitDiagnoses.length > 0 ? (
                  <ul>
                    {visitDiagnoses.map((diag) => (
                      <li key={diag.id}>
                        {diag.diagnosis}
                        {diag.filePath && (
                          <Button
                            variant="text"
                            color="primary"
                            startIcon={<FaFileAlt />}
                            onClick={() => handleOpenDocument(diag.filePath)}
                            sx={{ marginLeft: '10px' }}
                          >
                            Voir le document
                          </Button>
                        )}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <Typography variant="body2">Aucun diagnostic disponible</Typography>
                )}
              </TableCell>
            </StyledTableRow>
            <StyledTableRow>
              <TableCell colSpan={5}>
                <Typography variant="subtitle1">Traitements :</Typography>
                {visitTreatments.length > 0 ? (
                  <ul>
                    {visitTreatments.map((treat) => (
                      <li key={treat.id}>{treat.treatment}</li>
                    ))}
                  </ul>
                ) : (
                  <Typography variant="body2">Aucun traitement disponible</Typography>
                )}
              </TableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </StyledTableContainer>
    );
  })}
</TabPanel>

          <TabPanel value={tabIndex} index={1}>
          
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, flexWrap: 'wrap',  }}>
                  
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date de visite"
                    value={dateFilter}
                    onChange={handleDateFilterChange}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                    <Select
                      value={action}
                      onChange={handleActionChange}
                      displayEmpty
                      sx={{ ml: 2, color: theme.palette.primary.main, fontWeight: 'bold', borderBottom: `2px solid ${theme.palette.primary.main}` }}
                    >
                      <MenuItem value="" disabled>
                        Choisir une action
                      </MenuItem>

                      <MenuItem value="edit" disabled={!selected}>
                      Diagnostiques | Traitements | Factures
                      </MenuItem>

                      <MenuItem value="delete" disabled={!selected}>
                        Supprimer
                      </MenuItem>
                    </Select>
                  </Box>
                  
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, flexWrap: 'wrap' }}>

                </Box>

                {/* Table for appointments */}
                <StyledTableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell padding="checkbox">
                          <RadioGroup
                            name="appointmentSelection"
                            value={selected}
                            onChange={(e) => handleAppointmentClick(e.target.value)}
                          >
                            <FormControlLabel
                              value="all"
                              control={<Radio color="primary" />}
                              label=""
                            />
                          </RadioGroup>
                        </StyledTableCell>
                        <StyledTableCell>Image</StyledTableCell>
                        <StyledTableCell>
                          <TableSortLabel
                            active={orderBy === 'visitDate'}
                            direction={orderBy === 'visitDate' ? order : 'asc'}
                            onClick={() => handleRequestSort('visitDate')}
                          >
                            Date de visite
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell>Patient</StyledTableCell>
                        <StyledTableCell>Médecin</StyledTableCell>
                        <StyledTableCell>Motif</StyledTableCell>
                        <StyledTableCell>Notes</StyledTableCell>
                        <StyledTableCell>Statut</StyledTableCell>
                      </TableRow>
                    </TableHead>


                  <TableBody>
              {sortedAppointments.map((appointment) => {
                const isItemSelected = isSelected(appointment.id);
                const doctor = doctors.find((doc) => doc.id === appointment.doctorId);
              //const patient = patients.find((p) => p.id === appointment.patientId); // Sucht den Patienten anhand der patientId

                return (
                  <StyledTableRow
                    hover
                    onClick={() => handleAppointmentClick(appointment.id)}
                    role="radio"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={appointment.id}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Radio
                        color="primary"
                        checked={isItemSelected}
                        onChange={() => handleAppointmentClick(appointment.id)}
                        value={appointment.id}
                      />
                    </TableCell>
                    <TableCell>
                      <Avatar
                        src={doctor?.imageUrl}
                        alt={doctor?.firstName}
                        sx={{
                          width: 60,
                          height: 60,
                        }}
                      />
                    </TableCell>
                    <TableCell>{dayjs(appointment.visitDate).format('DD/MM/YYYY HH:mm')}</TableCell>
                    <TableCell>{patient ? `${patient.firstName} ${patient.lastName}` : 'Inconnu'}</TableCell> {/* Zeigt den Namen des Patienten an */}
                    <TableCell>{doctor ? `${doctor.firstName} ${doctor.lastName}` : 'Inconnu'}</TableCell>
                    <TableCell>{appointment.reason}</TableCell>
                    <TableCell>{appointment.notes}</TableCell>
                 

                    <TableCell>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={appointment.status === 'complété'}
                              onChange={() => handleStatusChange(appointment.id, appointment.status === 'complété' ? 'ouvert' : 'complété')}
                              color="primary"
                            />
                          }
                          label={appointment.status === 'complété' ? 'Complété' : 'Ouvert'}
                        />
                      </TableCell>

                  </StyledTableRow>
                );
              })}
            </TableBody>

                  </Table>
                </StyledTableContainer>

                <AddAppointmentDialog
                        open={addAppointmentOpen}
                        onClose={handleAddAppointmentClose}
                        onAddAppointment={handleAddAppointment}
                        patients={patients}
                        gewaehlerpatient={patient}
                        doctors={doctors}
                        tenants={tenants}
                        visits={visits}
                      />
                      {selectedAppointment && (
                        <>
     

                          <EditAppointmentDialog
                            open={editAppointmentOpen}
                            onClose={handleEditAppointmentClose}
                            appointment={selectedAppointment}
                            onEditAppointment={handleEditAppointment}
                            patients={patients}
                            doctors={doctors}
                            tenants={tenants}
                            partners={partners} // <-- Partnerdaten übergeben
                            diagnoses={diagnoses}
                            treatments={treatments}
                            invoiceItems={invoiceItems}
                            totalAmount={totalAmount}
                      
                          />
                        </>
                      )}
                      <Dialog
                        open={deleteConfirmOpen}
                        onClose={() => setDeleteConfirmOpen(false)}
                      >
                        <DialogTitle>Confirmer la suppression</DialogTitle>
                        <DialogContent>
                          <DialogContentText>
                            Êtes-vous sûr de vouloir supprimer le rendez-vous sélectionné ?
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={() => setDeleteConfirmOpen(false)} color="primary">
                            Annuler
                          </Button>
                          <Button onClick={handleDeleteAppointment} color="primary">
                            Supprimer
                          </Button>
                        </DialogActions>
                      </Dialog>
                      <Snackbar
                        open={deleteSuccessOpen}
                        autoHideDuration={6000}
                        onClose={() => setDeleteSuccessOpen(false)}
                      >
                        <Alert onClose={() => setDeleteSuccessOpen(false)} severity="success">
                          Rendez-vous supprimé avec succès !
                        </Alert>
                      </Snackbar>
                      <Snackbar
                        open={deleteErrorOpen}
                        autoHideDuration={6000}
                        onClose={() => setDeleteErrorOpen(false)}
                      >
                        <Alert onClose={() => setDeleteErrorOpen(false)} severity="error">
                          Échec de la suppression du rendez-vous !
                        </Alert>
                      </Snackbar>
                      <Snackbar
                        open={addSuccessOpen}
                        autoHideDuration={6000}
                        onClose={() => setAddSuccessOpen(false)}
                      >
                        <Alert onClose={() => setAddSuccessOpen(false)} severity="success">
                          Rendez-vous ajouté avec succès !
                        </Alert>
                      </Snackbar>
                      <Snackbar
                        open={addErrorOpen}
                        autoHideDuration={6000}
                        onClose={() => setAddErrorOpen(false)}
                      >
                        <Alert onClose={() => setAddErrorOpen(false)} severity="error">
                          Échec de l'ajout du rendez-vous !
                        </Alert>
                      </Snackbar>
                      <Snackbar
                        open={editSuccessOpen}
                        autoHideDuration={6000}
                        onClose={() => setEditSuccessOpen(false)}
                      >
                        <Alert onClose={() => setEditSuccessOpen(false)} severity="success">
                          Rendez-vous mis à jour avec succès !
                        </Alert>
                      </Snackbar>
                      <Snackbar
                        open={editErrorOpen}
                        autoHideDuration={6000}
                        onClose={() => setEditErrorOpen(false)}
                      >
                        <Alert onClose={() => setEditErrorOpen(false)} severity="error">
                          Échec de la mise à jour du rendez-vous !
                        </Alert>
                      </Snackbar>


</TabPanel>

          <DialogActions>
            <Button onClick={onClose}>Fermer</Button>
          </DialogActions>


        </DialogContent>
      </Dialog>

      {/* Modal to display document */}
<Dialog open={documentOpen} onClose={handleCloseDocument} fullWidth maxWidth="lg">
  <DialogTitle>Afficher le document</DialogTitle>
  <DialogContent>
    {documentUrl && (
      <>
        {documentUrl.toLowerCase().endsWith('.pdf') ? (
          <iframe
            src={documentUrl}
            title="Document PDF"
            width="100%"
            height="600px"
            style={{ border: 'none' }}
          />
        ) : (
          <img src={documentUrl} alt="Document" style={{ width: '100%', height: 'auto' }} />
        )}
      </>
    )}
  </DialogContent>
  <DialogActions>
    <Button onClick={handleCloseDocument} color="primary">
      Fermer
    </Button>
  </DialogActions>
</Dialog>


      {/* Modal pour afficher le document (optionnel, peut être supprimé si non utilisé) */}
      {/* Comme vous souhaitez ouvrir les documents directement dans le navigateur, ce modal n'est pas nécessaire */}
    </>
  );
};
