import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  IconButton,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
  Tabs,
  Tab,
  CssBaseline,
  AppBar as MuiAppBar,
  Toolbar,
  Badge,
  Divider,
  List,
  Drawer as MuiDrawer,
} from '@mui/material';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { mainListItems, secondaryListItems } from './listItems';

const drawerWidth = 240;
const theme = createTheme({
  palette: {
    primary: {
      main: '#22C55E', // Lebendiges Grün für Akzente
    },
    secondary: {
      main: '#A7F3D0', // Weiches, pastelliges Grün für Hintergrundbereiche
    },
    background: {
      default: '#F0FDF4', // Sehr helles Grün für den Haupt-Hintergrund
      paper: '#FFFFFF', // Weiß für Karten und Dialoge
    },
    text: {
      primary: '#065F46', // Dunkler Grünton für Text
      secondary: '#065F46',
    },
  },
  shape: {
    borderRadius: 16, // Sanfte Rundungen für modernes Design
  },
  shadows: [
    'none',
    '0px 4px 12px rgba(0, 0, 0, 0.1)', // Weicher Schatten für Karten und Komponenten
  ],
  typography: {
    fontFamily: 'Arial, sans-serif',
    h6: {
      fontWeight: 700,
    },
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: theme.palette.primary.main,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      backgroundColor: theme.palette.background.default,
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  })
);

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 345,
  margin: 'auto',
  transition: '0.3s',
  boxShadow: theme.shadows[1],
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)',
  },
}));


function RoleDepartmentConfig() {
  const [roles, setRoles] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [roleDepartments, setRoleDepartments] = useState({});
  const [selectedRole, setSelectedRole] = useState(null);
  const [addRoleOpen, setAddRoleOpen] = useState(false);
  const [newRole, setNewRole] = useState({ name: '' });
  const [users, setUsers] = useState([]);
  const [addUserOpen, setAddUserOpen] = useState(false);
  const [newUser, setNewUser] = useState({ 
    firstName: '', 
    lastName: '', 
    userName: '', 
    password: '', 
    roleId: '', 
    status: 1, 
    doctorId: '', 
    tenantId: '', 
    isSuperUser: false 
  });
  const [editUserOpen, setEditUserOpen] = useState(false);
  const [userToEdit, setUserToEdit] = useState(null);
  const [editRoleOpen, setEditRoleOpen] = useState(false);
  const [roleToEdit, setRoleToEdit] = useState(null);
  const [tenants, setTenants] = useState([]);
  const [doctors, setDoctors] = useState([]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const fetchRoles = async () => {
    try {
      const response = await axios.get('api/userRoles');
      setRoles(response.data);
    } catch (error) {
      console.error('Échec du chargement des rôles', error);
      setSnackbarMessage('Erreur lors du chargement des rôles.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const fetchDepartments = async () => {
    try {
      const response = await axios.get('api/departments');
      setDepartments(response.data);
    } catch (error) {
      console.error('Échec du chargement des départements', error);
      setSnackbarMessage('Erreur lors du chargement des départements.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const fetchRoleDepartments = async () => {
    try {
      const response = await axios.get('api/roleDepartments');
      const roleDeptMap = response.data.reduce((map, rd) => {
        if (!map[rd.roleName]) {
          map[rd.roleName] = new Set();
        }
        map[rd.roleName].add(rd.departmentName);
        return map;
      }, {});
      setRoleDepartments(roleDeptMap);
    } catch (error) {
      console.error('Échec du chargement des départements de rôle', error);
      setSnackbarMessage('Erreur lors du chargement des départements de rôle.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get('api/users');
      setUsers(response.data);
    } catch (error) {
      console.error('Échec du chargement des utilisateurs', error);
      setSnackbarMessage('Erreur lors du chargement des utilisateurs.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const fetchTenants = async () => {
    try {
      const response = await axios.get('api/tenants');
      setTenants(response.data);
    } catch (error) {
      console.error('Échec du chargement des locataires', error);
      setSnackbarMessage('Erreur lors du chargement des locataires.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const fetchDoctors = async () => {
    try {
      const response = await axios.get('api/doctors');
      setDoctors(response.data);
    } catch (error) {
      console.error('Échec du chargement des docteurs', error);
      setSnackbarMessage('Erreur lors du chargement des docteurs.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    fetchRoles();
    fetchDepartments();
    fetchRoleDepartments();
    fetchUsers();
    fetchTenants();
    fetchDoctors();
  }, []);

  const handleToggleDepartment = async (roleName, departmentName, isAssigned) => {
    const role = roles.find(r => r.RoleName === roleName);
    const department = departments.find(d => d.name === departmentName);

    try {
      if (isAssigned) {
        await axios.delete('api/roleDepartments', {
          data: { roleID: role.id, department_id: department.id }
        });
        setRoleDepartments(prev => ({
          ...prev,
          [roleName]: new Set([...prev[roleName]].filter(d => d !== departmentName))
        }));
      } else {
        await axios.post('api/roleDepartments', {
          roleID: role.id,
          department_id: department.id
        });
        setRoleDepartments(prev => ({
          ...prev,
          [roleName]: new Set(prev[roleName] ? [...prev[roleName], departmentName] : [departmentName])
        }));
      }
      setSnackbarMessage(`Département ${isAssigned ? 'retiré de' : 'assigné à'} rôle ${roleName}.`);
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Échec de la bascule du département de rôle', error);
      setSnackbarMessage('Erreur lors de la bascule du département de rôle.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleAddUser = async () => {
    if (
      newUser.firstName.trim() === '' ||
      newUser.lastName.trim() === '' ||
      newUser.userName.trim() === '' ||
      newUser.password.trim() === '' ||
      newUser.roleId === '' ||
      newUser.status === undefined ||
      newUser.tenantId === ''
    ) {
      setSnackbarMessage('Tous les champs (Prénom, Nom, userName, Mot de passe, Rôle, Statut, Locataire) doivent être remplis.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    try {
      const response = await axios.post('api/users', newUser);
      setUsers([...users, response.data]);
      setAddUserOpen(false);
      setNewUser({ firstName: '', lastName: '', userName: '', password: '', roleId: '', status: 1, doctorId: '', tenantId: '', isSuperUser: false });
      setSnackbarMessage('Utilisateur ajouté avec succès.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Échec de l\'ajout de l\'utilisateur', error);
      setSnackbarMessage('Erreur lors de l\'ajout de l\'utilisateur.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleEditUser = (user) => {
    setUserToEdit(user);
    setNewUser({ 
      firstName: user.firstName, 
      lastName: user.lastName, 
      userName: user.userName, 
      password: '', 
      roleId: user.roleId, 
      status: user.status, 
      doctorId: user.doctorId, 
      tenantId: user.tenantId, 
      isSuperUser: user.isSuperUser 
    });
    setEditUserOpen(true);
  };

  const handleUpdateUser = async () => {
    if (
      newUser.firstName.trim() === '' ||
      newUser.lastName.trim() === '' ||
      newUser.userName.trim() === '' ||
      newUser.roleId === '' ||
      newUser.status === undefined ||
      newUser.tenantId === ''
    ) {
      setSnackbarMessage('Prénom, Nom, userName, Rôle, Statut et Locataire ne doivent pas être vides.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    try {
      await axios.put(`users/${userToEdit.id}`, newUser);
      setUsers(users.map(u => (u.id === userToEdit.id ? { ...u, ...newUser } : u)));
      setEditUserOpen(false);
      setUserToEdit(null);
      setNewUser({ firstName: '', lastName: '', userName: '', password: '', roleId: '', status: 1, doctorId: '', tenantId: '', isSuperUser: false });
      setSnackbarMessage('Utilisateur mis à jour avec succès.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Échec de la mise à jour de l\'utilisateur', error);
      setSnackbarMessage('Erreur lors de la mise à jour de l\'utilisateur.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      await axios.delete(`/users/${userId}`);
      setUsers(users.filter(u => u.id !== userId));
      setSnackbarMessage('Utilisateur supprimé avec succès.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Échec de la suppression de l\'utilisateur', error);
      setSnackbarMessage('Erreur lors de la suppression de l\'utilisateur.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleAddRole = async () => {
    if (newRole.name.trim() === '') {
      setSnackbarMessage('Le nom du rôle ne doit pas être vide.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    try {
      const response = await axios.post('api/userRoles', { RoleName: newRole.name });
      setRoles([...roles, response.data]);
      setAddRoleOpen(false);
      setNewRole({ name: '' });
      setSnackbarMessage('Rôle ajouté avec succès.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Échec de l\'ajout du rôle', error);
      setSnackbarMessage('Erreur lors de l\'ajout du rôle.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleEditRole = (role) => {
    setRoleToEdit(role);
    setNewRole({ name: role.RoleName });
    setEditRoleOpen(true);
  };

  const handleUpdateRole = async () => {
    if (newRole.name.trim() === '') {
      setSnackbarMessage('Le nom du rôle ne doit pas être vide.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    try {
      await axios.put(`/userRoles/${roleToEdit.id}`, { RoleName: newRole.name });
      setRoles(roles.map(r => (r.id === roleToEdit.id ? { ...r, RoleName: newRole.name } : r)));
      setEditRoleOpen(false);
      setRoleToEdit(null);
      setNewRole({ name: '' });
      setSnackbarMessage('Rôle mis à jour avec succès.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Échec de la mise à jour du rôle', error);
      setSnackbarMessage('Erreur lors de la mise à jour du rôle.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleDeleteRole = async (roleId) => {
    try {
      await axios.delete(`/userRoles/${roleId}`);
      setRoles(roles.filter(r => r.id !== roleId));
      setSnackbarMessage('Rôle supprimé avec succès.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Échec de la suppression du rôle', error);
      setSnackbarMessage('Erreur lors de la suppression du rôle.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarMessage('');
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Départements pour les rôles des utilisateurs
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FormControl fullWidth margin="normal">
            <InputLabel>Rôle utilisateur</InputLabel>
            <Select
              value={selectedRole || ''}
              onChange={(e) => setSelectedRole(e.target.value)}
            >
              {roles.map((role) => (
                <MenuItem key={role.id} value={role.RoleName}>
                  <Box display="flex" alignItems="center">
                    <Typography variant="body2" flexGrow={1}>{role.RoleName}</Typography>
                    <IconButton size="small" onClick={() => handleEditRole(role)}>
                      <EditIcon fontSize="small" style={{ color: 'blue' }} />
                    </IconButton>
                    <IconButton size="small" onClick={() => handleDeleteRole(role.id)}>
                      <DeleteIcon fontSize="small" style={{ color: 'blue' }} />
                    </IconButton>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setAddRoleOpen(true)}
            sx={{ ml: 2 }}
          >
            Ajouter un rôle
          </Button>
        </Grid>
        {selectedRole && (
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Départements pour {selectedRole}
            </Typography>
            <Grid container spacing={3}>
              {departments.map((dept) => (
                <Grid item key={dept.id} xs={12} sm={6} md={4} lg={3}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6">{dept.name}</Typography>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={roleDepartments[selectedRole]?.has(dept.name) || false}
                            onChange={() => handleToggleDepartment(selectedRole, dept.name, roleDepartments[selectedRole]?.has(dept.name))}
                          />
                        }
                        label="Assigner"
                      />
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
      <Dialog open={addRoleOpen} onClose={() => setAddRoleOpen(false)}>
        <DialogTitle>Ajouter un nouveau rôle utilisateur</DialogTitle>
        <DialogContent>
          <TextField
            label="Nom du rôle"
            value={newRole.name}
            onChange={(e) => setNewRole({ ...newRole, name: e.target.value })}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddRoleOpen(false)} color="primary">
            Annuler
          </Button>
          <Button onClick={handleAddRole} color="primary">
            Ajouter
          </Button>
        </DialogActions>
      </Dialog>
      <Box mt={4}>
        <Typography variant="h6" gutterBottom>
          Gestion des utilisateurs
        </Typography>
        <Button variant="contained" color="primary" onClick={() => setAddUserOpen(true)} sx={{ mr: 2 }}>
          Ajouter un utilisateur
        </Button>
        <Grid container spacing={3} mt={2}>
          {users.map((user) => (
            <Grid item key={user.id} xs={12} sm={6} md={4} lg={3}>
              <Card>
                <CardContent>
                  <Typography variant="h6">{user.firstName} {user.lastName}</Typography>
                  <Typography variant="body2">{roles.find(r => r.id === user.roleId)?.RoleName}</Typography>
                  <Typography variant="body2">Docteur : {user.doctorFirstName} {user.doctorLastName}</Typography>
                  <Typography variant="body2">Statut : {user.status === 1 ? 'Actif' : 'Inactif'}</Typography>
                  <Typography variant="body2">Superuser : {user.isSuperUser ? 'Oui' : 'Non'}</Typography>
                  <IconButton size="small" onClick={() => handleEditUser(user)}>
                    <EditIcon fontSize="small" style={{ color: 'blue' }} />
                  </IconButton>
                  <IconButton size="small" onClick={() => handleDeleteUser(user.id)}>
                    <DeleteIcon fontSize="small" style={{ color: 'blue' }} />
                  </IconButton>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Dialog open={addUserOpen} onClose={() => setAddUserOpen(false)}>
          <DialogTitle>Ajouter un nouvel utilisateur</DialogTitle>
          <DialogContent>
            <TextField
              label="Prénom"
              value={newUser.firstName}
              onChange={(e) => setNewUser({ ...newUser, firstName: e.target.value })}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Nom"
              value={newUser.lastName}
              onChange={(e) => setNewUser({ ...newUser, lastName: e.target.value })}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Nom Utilisateurs"
              value={newUser.userName}
              onChange={(e) => setNewUser({ ...newUser, userName: e.target.value })}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Mot de passe"
              type="password"
              value={newUser.password}
              onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
              fullWidth
              margin="normal"
            />
            <TextField
              select
              label="Rôle"
              value={newUser.roleId}
              onChange={(e) => setNewUser({ ...newUser, roleId: e.target.value })}
              fullWidth
              margin="normal"
            >
              {roles.map((role) => (
                <MenuItem key={role.id} value={role.id}>
                  {role.RoleName}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              label="Locataire"
              value={newUser.tenantId}
              onChange={(e) => setNewUser({ ...newUser, tenantId: e.target.value })}
              fullWidth
              margin="normal"
            >
              {tenants.map((tenant) => (
                <MenuItem key={tenant.id} value={tenant.id}>
                  {tenant.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              label="Docteur"
              value={newUser.doctorId}
              onChange={(e) => setNewUser({ ...newUser, doctorId: e.target.value })}
              fullWidth
              margin="normal"
            >
              {doctors.map((doctor) => (
                <MenuItem key={doctor.id} value={doctor.id}>
                  {doctor.firstName} {doctor.lastName}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              label="Statut"
              value={newUser.status}
              onChange={(e) => setNewUser({ ...newUser, status: e.target.value })}
              fullWidth
              margin="normal"
            >
              <MenuItem value={1}>Actif</MenuItem>
              <MenuItem value={0}>Inactif</MenuItem>
            </TextField>
            <FormControlLabel
              control={
                <Switch
                  checked={newUser.isSuperUser}
                  onChange={(e) => setNewUser({ ...newUser, isSuperUser: e.target.checked })}
                />
              }
              label="Superuser"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setAddUserOpen(false)} color="primary">
              Annuler
            </Button>
            <Button onClick={handleAddUser} color="primary">
              Ajouter
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={editUserOpen} onClose={() => setEditUserOpen(false)}>
          <DialogTitle>Modifier l'utilisateur</DialogTitle>
          <DialogContent>
            <TextField
              label="Prénom"
              value={newUser.firstName}
              onChange={(e) => setNewUser({ ...newUser, firstName: e.target.value })}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Nom"
              value={newUser.lastName}
              onChange={(e) => setNewUser({ ...newUser, lastName: e.target.value })}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Nom Utilisateurs"
              value={newUser.userName}
              onChange={(e) => setNewUser({ ...newUser, userName: e.target.value })}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Mot de passe"
              type="password"
              value={newUser.password}
              onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
              fullWidth
              margin="normal"
            />
            <TextField
              select
              label="Rôle"
              value={newUser.roleId}
              onChange={(e) => setNewUser({ ...newUser, roleId: e.target.value })}
              fullWidth
              margin="normal"
            >
              {roles.map((role) => (
                <MenuItem key={role.id} value={role.id}>
                  {role.RoleName}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              label="Locataire"
              value={newUser.tenantId}
              onChange={(e) => setNewUser({ ...newUser, tenantId: e.target.value })}
              fullWidth
              margin="normal"
            >
              {tenants.map((tenant) => (
                <MenuItem key={tenant.id} value={tenant.id}>
                  {tenant.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              label="Docteur"
              value={newUser.doctorId}
              onChange={(e) => setNewUser({ ...newUser, doctorId: e.target.value })}
              fullWidth
              margin="normal"
            >
              {doctors.map((doctor) => (
                <MenuItem key={doctor.id} value={doctor.id}>
                  {doctor.firstName} {doctor.lastName}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              label="Statut"
              value={newUser.status}
              onChange={(e) => setNewUser({ ...newUser, status: e.target.value })}
              fullWidth
              margin="normal"
            >
              <MenuItem value={1}>Actif</MenuItem>
              <MenuItem value={0}>Inactif</MenuItem>
            </TextField>
            <FormControlLabel
              control={
                <Switch
                  checked={newUser.isSuperUser}
                  onChange={(e) => setNewUser({ ...newUser, isSuperUser: e.target.checked })}
                />
              }
              label="Superuser"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEditUserOpen(false)} color="primary">
              Annuler
            </Button>
            <Button onClick={handleUpdateUser} color="primary">
              Mettre à jour
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={addRoleOpen} onClose={() => setAddRoleOpen(false)}>
          <DialogTitle>Ajouter un nouveau rôle utilisateur</DialogTitle>
          <DialogContent>
            <TextField
              label="Nom du rôle"
              value={newRole.name}
              onChange={(e) => setNewRole({ ...newRole, name: e.target.value })}
              fullWidth
              margin="normal"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setAddRoleOpen(false)} color="primary">
              Annuler
            </Button>
            <Button onClick={handleAddRole} color="primary">
              Ajouter
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
}

function TenantConfig() {
  const [tenants, setTenants] = useState([]);
  const [addTenantOpen, setAddTenantOpen] = useState(false);
  const [editTenantOpen, setEditTenantOpen] = useState(false);
  const [newTenant, setNewTenant] = useState({
    name: '',
    email: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    phoneNumber: '',
    website: '',
    foundingDate: '',
    companySize: '',
    industry: '',
    contractStartDate: '',
    contractEndDate: '',
    contractStatus: '',
    imageUrl: '',
    payPeriod: ''
  });
  const [tenantToEdit, setTenantToEdit] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const fetchTenants = async () => {
    try {
      const response = await axios.get('api/tenants');
      setTenants(response.data);
    } catch (error) {
      console.error('Échec du chargement des locataires', error);
    }
  };

  useEffect(() => {
    fetchTenants();
  }, []);

  const handleAddTenant = async () => {
    try {
      const response = await axios.post('api/tenants', newTenant);
      setTenants([...tenants, response.data]);
      setAddTenantOpen(false);
      setNewTenant({
        name: '',
        email: '',
        address: '',
        city: '',
        state: '',
        zipCode: '',
        country: '',
        phoneNumber: '',
        website: '',
        foundingDate: '',
        companySize: '',
        industry: '',
        contractStartDate: '',
        contractEndDate: '',
        contractStatus: '',
        imageUrl: '',
        payPeriod: ''
      });
      setSnackbarMessage('Locataire ajouté avec succès.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Échec de l\'ajout du locataire', error);
      setSnackbarMessage('Erreur lors de l\'ajout du locataire.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleEditTenant = (tenant) => {
    setTenantToEdit(tenant);
    setNewTenant({
      name: tenant.name,
      email: tenant.email,
      address: tenant.address,
      city: tenant.city,
      state: tenant.state,
      zipCode: tenant.zipCode,
      country: tenant.country,
      phoneNumber: tenant.phoneNumber,
      website: tenant.website,
      foundingDate: tenant.foundingDate,
      companySize: tenant.companySize,
      industry: tenant.industry,
      contractStartDate: tenant.contractStartDate,
      contractEndDate: tenant.contractEndDate,
      contractStatus: tenant.contractStatus,
      imageUrl: tenant.imageUrl
    });
    setEditTenantOpen(true);
  };

  const handleUpdateTenant = async () => {
    try {
      await axios.put(`api/tenants/${tenantToEdit.id}`, newTenant);
      setTenants(tenants.map((t) => (t.id === tenantToEdit.id ? { ...t, ...newTenant } : t)));
      setEditTenantOpen(false);
      setTenantToEdit(null);
      setNewTenant({
        name: '',
        email: '',
        address: '',
        city: '',
        state: '',
        zipCode: '',
        country: '',
        phoneNumber: '',
        website: '',
        foundingDate: '',
        companySize: '',
        industry: '',
        contractStartDate: '',
        contractEndDate: '',
        contractStatus: '',
        imageUrl: '',
        payPeriod: ''
      });
      setSnackbarMessage('Locataire mis à jour avec succès.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Échec de la mise à jour du locataire', error);
      setSnackbarMessage('Erreur lors de la mise à jour du locataire.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleDeleteTenant = async (tenantId) => {
    try {
      await axios.delete(`api/tenants/${tenantId}`);
      setTenants(tenants.filter((t) => t.id !== tenantId));
      setSnackbarMessage('Locataire supprimé avec succès.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Échec de la suppression du locataire', error);
      setSnackbarMessage('Erreur lors de la suppression du locataire.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarMessage('');
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = `/images/${file.name}`;
      setNewTenant({ ...newTenant, imageUrl });
    }
  };

  const country = [
    "Mauritanie",
    "Maroc"
  ];
  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Locataires
      </Typography>
      <Grid container spacing={3}>
        {tenants.map((tenant) => (
          <Grid item key={tenant.id} xs={12} sm={6} md={4} lg={3}>
            <StyledCard>
              <CardMedia
                component="img"
                image={tenant.imageUrl || 'https://via.placeholder.com/200'}
                title={tenant.name}
                sx={{ height: 200 }}
              />
              <CardContent>
                <Typography variant="h6">{tenant.name}</Typography>
                <Typography variant="body2">{tenant.email}</Typography>
                <Typography variant="body2">{tenant.address}</Typography>
                <Typography variant="body2">{tenant.city}, {tenant.state} {tenant.zipCode}</Typography>
                <Typography variant="body2">{tenant.country}</Typography>
                <Typography variant="body2">{tenant.phoneNumber}</Typography>
                <Typography variant="body2">{tenant.website}</Typography>
                <Typography variant="body2">{tenant.foundingDate}</Typography>
                <Typography variant="body2">{tenant.companySize}</Typography>
                <Typography variant="body2">{tenant.industry}</Typography>
                <Typography variant="body2">{tenant.contractStartDate} - {tenant.contractEndDate}</Typography>
                <Typography variant="body2">{tenant.contractStatus}</Typography>
                <Typography variant="body2">{tenant.payPeriod}</Typography>
                
                <IconButton size="small" onClick={() => handleEditTenant(tenant)}>
                  <EditIcon fontSize="small" style={{ color: 'blue' }} />
                </IconButton>
                <IconButton size="small" onClick={() => handleDeleteTenant(tenant.id)}>
                  <DeleteIcon fontSize="small" style={{ color: 'blue' }} />
                </IconButton>
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <StyledCard onClick={() => setAddTenantOpen(true)}>
            <CardMedia
              component="img"
              image="https://via.placeholder.com/200"
              title="Ajouter un nouveau locataire"
              sx={{ height: 200 }}
            />
            <CardContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <AddCircleOutlineIcon style={{ fontSize: 50, color: 'blue' }} />
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>
      <Dialog open={addTenantOpen} onClose={() => setAddTenantOpen(false)}>
        <DialogTitle>Ajouter un nouveau locataire</DialogTitle>
        <DialogContent>
          <TextField
            label="Nom"
            value={newTenant.name}
            onChange={(e) => setNewTenant({ ...newTenant, name: e.target.value })}
            fullWidth
            margin="normal"
          />
            <FormControl fullWidth margin="normal">
              <InputLabel>Pays</InputLabel>
              <Select
                value={newTenant.country}
                onChange={(e) => setNewTenant({ ...newTenant, country: e.target.value })}
              >
                {country.map((country) => (
                  <MenuItem key={country} value={country}>
                    {country}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>


           <TextField
            label="Adresse"
            value={newTenant.address}
            onChange={(e) => setNewTenant({ ...newTenant, address: e.target.value })}
            fullWidth
            margin="normal"
          />
           <TextField
            label="Numéro de téléphone"
            value={newTenant.phoneNumber}
            onChange={(e) => setNewTenant({ ...newTenant, phoneNumber: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="E-mail"
            value={newTenant.email}
            onChange={(e) => setNewTenant({ ...newTenant, email: e.target.value })}
            fullWidth
            margin="normal"
          />
         
         
        
           <TextField
            label="Période de paie "
            value={newTenant.payPeriod}
            onChange={(e) => setNewTenant({ ...newTenant, payPeriod: e.target.value })}
            fullWidth
            margin="normal"
          />


          <Button variant="contained" component="label" sx={{ mt: 2 }}>
            Sélectionner une image
            <input type="file" hidden onChange={handleImageChange} />
          </Button>
          {newTenant.imageUrl && (
            <Typography variant="body2" sx={{ mt: 2 }}>
              Image sélectionnée : {newTenant.imageUrl}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddTenantOpen(false)} color="primary">
            Annuler
          </Button>
          <Button onClick={handleAddTenant} color="primary">
            Ajouter
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={editTenantOpen} onClose={() => setEditTenantOpen(false)}>
        <DialogTitle>Modifier le locataire</DialogTitle>
        <DialogContent>
          <TextField
            label="Nom"
            value={newTenant.name}
            onChange={(e) => setNewTenant({ ...newTenant, name: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="E-mail"
            value={newTenant.email}
            onChange={(e) => setNewTenant({ ...newTenant, email: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Adresse"
            value={newTenant.address}
            onChange={(e) => setNewTenant({ ...newTenant, address: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Ville"
            value={newTenant.city}
            onChange={(e) => setNewTenant({ ...newTenant, city: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="État"
            value={newTenant.state}
            onChange={(e) => setNewTenant({ ...newTenant, state: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Code postal"
            value={newTenant.zipCode}
            onChange={(e) => setNewTenant({ ...newTenant, zipCode: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Pays"
            value={newTenant.country}
            onChange={(e) => setNewTenant({ ...newTenant, country: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Numéro de téléphone"
            value={newTenant.phoneNumber}
            onChange={(e) => setNewTenant({ ...newTenant, phoneNumber: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Site web"
            value={newTenant.website}
            onChange={(e) => setNewTenant({ ...newTenant, website: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Date de fondation"
            type="date"
            value={newTenant.foundingDate}
            onChange={(e) => setNewTenant({ ...newTenant, foundingDate: e.target.value })}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Taille de l'entreprise"
            value={newTenant.companySize}
            onChange={(e) => setNewTenant({ ...newTenant, companySize: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Secteur"
            value={newTenant.industry}
            onChange={(e) => setNewTenant({ ...newTenant, industry: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Date de début de contrat"
            type="date"
            value={newTenant.contractStartDate}
            onChange={(e) => setNewTenant({ ...newTenant, contractStartDate: e.target.value })}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Date de fin de contrat"
            type="date"
            value={newTenant.contractEndDate}
            onChange={(e) => setNewTenant({ ...newTenant, contractEndDate: e.target.value })}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Statut du contrat"
            value={newTenant.contractStatus}
            onChange={(e) => setNewTenant({ ...newTenant, contractStatus: e.target.value })}
            fullWidth
            margin="normal"
          />
          <Button variant="contained" component="label" sx={{ mt: 2 }}>
            Sélectionner une image
            <input type="file" hidden onChange={handleImageChange} />
          </Button>
          {newTenant.imageUrl && (
            <Typography variant="body2" sx={{ mt: 2 }}>
              Image sélectionnée : {newTenant.imageUrl}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditTenantOpen(false)} color="primary">
            Annuler
          </Button>
          <Button onClick={handleUpdateTenant} color="primary">
            Mettre à jour
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default function Dashboard() {
  const [open, setOpen] = useState(true);
  const [tabValue, setTabValue] = useState(0);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  

const navigate = useNavigate(); // Verwende useNavigate zum Weiterleiten

useEffect(() => {
  // Axios-Interceptor zur automatischen Hinzufügung des Tokens zu jeder Anfrage
  const requestInterceptor = axios.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token'); // Token aus dem lokalen Speicher abrufen
      if (token) {
        config.headers.Authorization = `Bearer ${token}`; // Token in den Authorization-Header setzen
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Axios-Interceptor zur Abfangung von abgelaufenen Token
  const responseInterceptor = axios.interceptors.response.use(
    (response) => {
      // Erfolgreiche Antwort - kein Handlungsbedarf
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        // Überprüfen, ob der Fehler auf einen abgelaufenen Token hinweist
        if (error.response.data.error === 'Token abgelaufen') {
          // Token aus dem lokalen Speicher entfernen
          localStorage.removeItem('token');
          // Redirect zur Login-Seite mit useNavigate
          navigate('api/login');
        }
      }
      return Promise.reject(error);
    }
  );

  // Cleanup function to eject the interceptors when the component unmounts
  return () => {
    axios.interceptors.request.eject(requestInterceptor);
    axios.interceptors.response.eject(responseInterceptor);
  };
}, [navigate]); // Abhängigkeit auf navigate setzen

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar sx={{ pr: '24px' }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
              Configuration de l'entreprise
            </Typography>
           
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 1 }} />
            {secondaryListItems}
          </List>
        </Drawer>
   
        <Box component="main" sx={{ flexGrow: 1, p: 3, backgroundColor: theme.palette.background.default }}>

          <Toolbar />
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab label="Rôles et Utilisateurs" />
            <Tab label="Mandataire" />

          </Tabs>

          {tabValue === 0 && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <RoleDepartmentConfig />
              </Grid>
            </Grid>
          )}
          {tabValue === 1 && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TenantConfig />
              </Grid>
            </Grid>
          )}
       
        </Box>
      </Box>
    </ThemeProvider>
  );
}
