import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { mainListItems, secondaryListItems } from './listItems';
import moment from 'moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import Tooltip from '@mui/material/Tooltip';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment/locale/fr';

moment.locale('fr');
const localizer = momentLocalizer(moment);

const drawerWidth = 240;

const messages = {
  allDay: 'Toute la journée',
  previous: 'Précédent',
  next: 'Suivant',
  today: "Aujourd'hui",
  month: 'Mois',
  week: 'Semaine',
  day: 'Jour',
  agenda: 'Agenda',
  date: 'Date',
  time: 'Heure',
  event: 'Événement',
  noEventsInRange: 'Aucun événement prévu dans cette période.',
  showMore: total => `+ ${total} plus`,
};
const AppointmentCalendar = ({ appointments }) => {
  
  const events = appointments.map(appointment => ({
    title: `${appointment.patient} `, // Include patient name and status
    start: new Date(appointment.visitDate),
    end: new Date(new Date(appointment.visitDate).getTime() + 30 * 60000),
    status: appointment.status,
    checkin: appointment.checkin,
    
    style: {
      backgroundColor: appointment.status === 'complété' 
        ? 'green' 
        : appointment.checkin === 'Arrivé' 
        ? '#FFC107' 
        : 'red',
      color: 'white',
    },
  }));
  

  const eventStyleGetter = event => ({
    style: {
      backgroundColor: event.style.backgroundColor,
      color: event.style.color,
      borderRadius: '5px',
      /* Increase the gutter width */
    
     

      border: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '4px',
      fontSize: '0.9rem', // Slightly smaller font for better fit
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis', // Ensures text fits within the event box
    },
  });

  return (
    <Box sx={{ height: '80vh', width: '100%' }}>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        defaultView="day"
        views={['month', 'week', 'day']}
        step={30}
        timeslots={2}
        selectable
        eventPropGetter={eventStyleGetter}
        messages={messages}
        tooltipAccessor={null} // Disable default tooltip that shows start and end times
        components={{
          event: ({ event }) => (
            <span style={{ display: 'block', textAlign: 'center' }} title="">
              {event.title}
            </span>
          ),
        }}
        
        onSelectEvent={event => console.log('Selected event: ', event)}
      />
    </Box>
  );
};


const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  })
);

const theme = createTheme({
  palette: {
    primary: {
      main: '#22C55E', // Kräftiges, lebendiges Grün für Hauptakzente
    },
    secondary: {
      main: '#A7F3D0', // Pastell-Grün für weiche Hintergrundbereiche
    },
    background: {
      default: '#F0FDF4', // Sehr helles Grün als Hintergrundfarbe, passend zu einem frischen Look
      paper: '#FFFFFF', // Reinweiß für Karten und Container
    },
    text: {
      primary: '#065F46', // Dunkler Grünton für den Haupttext, der gut zu Grün passt
      secondary: '#065F46', // Leicht abgedunkelter Grünton für sekundären Text
    },
  },
  shape: {
    borderRadius: 16, // Sanfte Rundung für Komponenten für einen modernen Stil
  },
  shadows: [
    'none',
    '0px 4px 12px rgba(0, 0, 0, 0.1)', // Sanfter Schatteneffekt, um Karten hervorzuheben
  ],
  typography: {
    fontFamily: 'Arial, sans-serif', // Alternativ eine saubere, moderne Schriftart
    h6: {
      fontWeight: 700, // Fettdruck für Überschriften
    },
  },
});

export default function Dashboard() {
  const [open, setOpen] = useState(true);
  const [appointments, setAppointments] = useState([]);
  const [patients, setPatients] = useState([]);
  const [editErrorOpen, setEditErrorOpen] = useState(false);
  const [editErrorMessage, setEditErrorMessage] = useState('');


  
  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        const visits = await axios.get('/api/visits');
        const patientsResponse = await axios.get('/api/patients'); // Correct the URL if needed
  
        console.log("API Response (Visits):", visits); // Log visits response
        console.log("API Response (Patients):", patientsResponse); // Log patients response
        
        if (Array.isArray(visits.data) && Array.isArray(patientsResponse.data)) {
          const formattedAppointments = visits.data.map(appointment => {
            const patient = patientsResponse.data.find(p => p.id === appointment.patientId); // Use patientsResponse.data
            return {
              status: appointment.status,
              checkin: appointment.checkIn,
              
              patient: patient ? patient.firstName : "Unbekannter Patient", // Use the found patient's name
              visitDate: appointment.visitDate, // Ensure date is in the correct format
              
            };
          });
          setAppointments(formattedAppointments);
        } else {
          console.error("Unexpected API response format:", { visits: visits.data, patients: patientsResponse.data });
        }
      } catch (error) {
        console.error("Error fetching appointments:", error);
      }
    };
  
    fetchAppointments();
  }, []);
  


  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', height: '100vh', overflow: 'hidden' }}>
        <Snackbar open={editErrorOpen} autoHideDuration={6000} onClose={() => setEditErrorOpen(false)}>
          <Alert onClose={() => setEditErrorOpen(false)} severity="error">
            {editErrorMessage}
          </Alert>
        </Snackbar>

        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar sx={{ pr: '24px' }}>
            <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={toggleDrawer}>
              <MenuIcon />
            </IconButton>
            <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
              Gestion des rendez-vous
            </Typography>
           
          </Toolbar>
        </AppBar>

        <Drawer variant="permanent" open={open}>
          <Toolbar sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', px: [1] }}>
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 4 }} />
            {secondaryListItems}
            <Divider sx={{ my: 4 }} />
          </List>
        </Drawer>

        <Box component="main" sx={{ flexGrow: 1, p: 3, height: '100vh', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
          <Toolbar />
          
          <AppointmentCalendar appointments={appointments} />
        </Box>
      </Box>
    </ThemeProvider>
  );
}
